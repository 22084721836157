// GmailEmailComposer.jsx
import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  Box,
  Icon,
  Image,
  Text
} from '@chakra-ui/react'
import { IconSend } from '@tabler/icons-react'
import { post } from '@app/lib/api'
import { getCurrentProject, projectPath } from './ProjectsContext'

interface EmailProps {
  to?: string
  subject?: string
  body?: string
}

const EmailComposer = ({ isOpen, onClose, onSend, email }) => {
  const [currentEmail, setCurrentEmail] = useState({
    to: '',
    subject: '',
    body: '',
    ...email
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setCurrentEmail((prev) => ({ ...prev, [name]: value }))
  }

  const handleSend = async () => {
    try {
      await onSend(currentEmail)
      onClose()
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap={3}>
          <Image
            src="https://lh3.googleusercontent.com/0rpHlrX8IG77awQMuUZpQ0zGWT7HRYtpncsuRnFo6V3c8Lh2hPjXnEuhDDd-OsLz1vua4ld2rlUYFAaBYk-rZCODmi2eJlwUEVsZgg"
            alt="Gmail Logo"
            boxSize={5}
          />
          <Text>Compose email</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>To</FormLabel>
              <Input name="to" value={currentEmail.to} onChange={handleChange} placeholder="recipient@example.com" />
            </FormControl>
            <FormControl>
              <FormLabel>Subject</FormLabel>
              <Input name="subject" value={currentEmail.subject} onChange={handleChange} placeholder="Email subject" />
            </FormControl>
            <FormControl>
              <FormLabel>Message</FormLabel>
              <Textarea
                name="body"
                value={currentEmail.body}
                onChange={handleChange}
                placeholder="Type your message here..."
                minHeight="200px"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSend}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface GmailEmailComposerProps {
  connected: boolean
  email?: EmailProps
  disabled?: boolean
  onEmailSent?: () => void
}

const GmailEmailComposer = (props: GmailEmailComposerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const hasGmailScope = props.connected
  const currentProjectSlug = getCurrentProject()?.slug
  const toast = useToast()

  const requestGmailScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = `/auth/gmail?project=${currentProjectSlug}&app_id=gmail&state=${returnTo}`
    } catch (error) {
      console.error('Error requesting Gmail scope:', error)
      toast({
        title: 'Error',
        description: 'Failed to request Gmail permissions',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const sendEmail = async (emailData) => {
    await post(projectPath('/apps/gmail'), { email: emailData })
      .then(() => {
        toast({
          title: 'Success',
          description: 'Email sent successfully',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        props.onEmailSent?.()
      })
      .catch((_) => {
        toast({
          title: 'Error',
          description: 'Failed to send email',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  return (
    <Box>
      {!hasGmailScope ? (
        <Button
          colorScheme="red"
          onClick={requestGmailScope}
          title="Allow koala sending emails on your behalf"
          size="sm"
          variant="outline"
        >
          <Image
            src="https://lh3.googleusercontent.com/0rpHlrX8IG77awQMuUZpQ0zGWT7HRYtpncsuRnFo6V3c8Lh2hPjXnEuhDDd-OsLz1vua4ld2rlUYFAaBYk-rZCODmi2eJlwUEVsZgg"
            alt="Gmail Logo"
            boxSize={4}
          />
          <Text ml={2}>Grant Gmail Access</Text>
        </Button>
      ) : (
        <Button
          colorScheme="blue"
          onClick={onOpen}
          title="Send over Gmail."
          variant="outline"
          size="sm"
          disabled={props.disabled}
        >
          <Icon as={IconSend} boxSize={4} />
        </Button>
      )}

      <EmailComposer isOpen={isOpen} onClose={onClose} onSend={sendEmail} email={props.email} />
    </Box>
  )
}

export default GmailEmailComposer
