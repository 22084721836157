import { Avatar, Box, AvatarGroup, Flex, HStack, Stack, Text, Tooltip, VStack } from '@chakra-ui/react'
import React from 'react'

const colors = ['green', 'blue', 'orange', 'gray']

// Add this new BucketSummary component above the graph
export function BucketSummary({ buckets }) {
  return (
    <Flex w="full" gap={4} flexWrap="nowrap" overflowX="auto">
      {buckets.map((bucket, index) => (
        <VStack
          key={bucket.key}
          padding={4}
          borderWidth="1px"
          borderRadius="md"
          align="start"
          spacing={2}
          flex="1"
          maxWidth="25%"
        >
          <HStack spacing={2}>
            <Text fontWeight="bold" color={`${colors[index]}.500`}>{`${bucket.key}`}</Text>
            <Text>
              {bucket.from && bucket.to
                ? `from ${bucket.from} to ${bucket.max_score} points`
                : bucket.from
                  ? `${bucket.from} points up`
                  : `up to ${bucket.max_score} points`}
            </Text>
          </HStack>
          {/* Company Logos using AvatarGroup */}
          <AvatarGroup size="md" max={5}>
            {bucket.sample_accounts.map((account) => (
              <Tooltip key={account.id} label={account.company?.domain} aria-label="Company name">
                <Avatar src={account.company?.logo} name={account.company?.domain} size="md" />
              </Tooltip>
            ))}
          </AvatarGroup>
        </VStack>
      ))}
    </Flex>
  )
}

export function DistributionBar({ buckets }) {
  return (
    <Stack spacing={4} align="center" width="100%">
      {/* Distribution labels */}
      <Flex justifyContent="space-between" width="100%" px={4}>
        {buckets.map((bucket, index) => (
          <Stack key={bucket.key} spacing={1} align="center" width="20%">
            <Text fontSize="sm" color={`${colors[index]}.500`}>
              {bucket.key}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              {bucket.doc_count}
            </Text>
            <Text color="gray.500" fontSize="sm">
              ({bucket.total_percent}% of all)
            </Text>
          </Stack>
        ))}
      </Flex>

      <Flex width="100%" flexDirection="column" align="center">
        <Flex width="100%" justifyContent="space-between" p={0} mb={1}>
          {buckets.map((bucket, index) => (
            <Text
              key={bucket.key}
              fontSize="sm"
              color={`${colors[index]}.500`}
              width={`${bucket.matching_percent <= 1 ? '2' : bucket.matching_percent}%`}
              marginRight={index === buckets.length - 1 ? 0 : '2px'}
              textAlign="right"
            >
              {bucket.matching_percent}%
            </Text>
          ))}
        </Flex>
        <Flex width="100%" height="16px" borderRadius="md" overflow="hidden">
          {buckets.map((bucket, index) => (
            <Box
              key={bucket.key}
              width={`${bucket.matching_percent <= 1 ? '2' : bucket.matching_percent}%`}
              bg={`${colors[index]}.500`}
              marginRight={index === buckets.length - 1 ? 0 : '2px'}
            />
          ))}
        </Flex>
      </Flex>
    </Stack>
  )
}

export function DisqualifiedSummary({ totalAccounts, totalMatching, disqualified }) {
  return (
    <Flex w="full" gap={4} flexWrap="nowrap" overflowX="auto">
      <VStack
        key="Disqulified"
        padding={4}
        borderWidth="1px"
        borderRadius="md"
        align="start"
        spacing={2}
        flex="1"
        maxWidth="25%"
      >
        <HStack spacing={2}>
          <Text fontWeight="bold">Disqualified</Text>
          <Text>--</Text>
        </HStack>
        {/* Company Logos using AvatarGroup */}
        <AvatarGroup size="md" max={5}>
          {disqualified.map((account) => (
            <Tooltip key={account.id} label={account.company?.domain} aria-label="Company name">
              <Avatar src={account.company?.logo} name={account.company?.domain} size="md" />
            </Tooltip>
          ))}
        </AvatarGroup>
        <Flex width="100%" flexDirection="column" align="center">
          <Text fontSize="lg" fontWeight="bold">
            {totalAccounts - totalMatching}
          </Text>
          <Text fontSize="sm" color="gray.500" width="100%" textAlign="center">
            {Math.round(((totalAccounts - totalMatching) / totalAccounts) * 100)}%
          </Text>
        </Flex>
        <Flex width="100%" height="16px" borderRadius="md" overflow="hidden">
          <Box key="disqualified" width="100%" bg="gray.500" />
        </Flex>
      </VStack>
    </Flex>
  )
}
