import React from 'react'
import { Apps } from '../../../types/App'
import PageLayout from '../../ui/PageLayout'
import { Persona } from '../prospects/personas'
import { ExploreProspectsView } from './components/ExploreProspectsView'

interface Props {
  personas: Persona[]
  apps: Apps
  selected_persona: Persona | null
}

export default function Index(props: Props) {
  return (
    <PageLayout flush gap={0} maxH="100%">
      <ExploreProspectsView
        personas={props.personas}
        selectedPersona={props.selected_persona}
        apps={props.apps}
      />
    </PageLayout>
  )
}
