import { Icon, Link } from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconAt,
  IconBolt,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBriefcase,
  IconBuilding,
  IconCalendar,
  IconCalendarMonth,
  IconCalendarTime,
  IconClick,
  IconClockPlay,
  IconCoin,
  IconCornerDownRight,
  IconFileTypeCsv,
  IconFlame,
  IconForms,
  IconHash,
  IconList,
  IconMail,
  IconMapPin,
  IconMapSearch,
  IconPackageExport,
  IconStack2,
  IconStar,
  IconTags,
  IconToggleLeft,
  IconTransform,
  IconTrendingUp,
  IconTrianglePlus,
  IconUser,
  IconUserCheck,
  IconUsers,
  IconUserSquare,
  IconWorldWww
} from '@tabler/icons-react'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import React from 'react'
import type { App } from '../../../../types/App'
import { TextIcon } from '../../../ui/icons'
import { IndexingEntitlements } from '../../billing/v2'
import type { FacetMapping, FacetMappings } from '../index'
import { humanize } from './filter-cloud'

type Operator = 'all' | 'any' | 'not' | 'gte' | 'lte' | 'exists' | 'not_exists' | 'between'

export interface FilterItem {
  id?: string
  key: string
  label: string
  category?: string
  icon?: TablerIcon | string | typeof Icon
  values?: any
  type?: FacetMapping['type'] | 'duration' | 'range_stats'
  allowFreeEntry?: boolean
  allowSubstringOperators?: boolean
  operators?: Operator[]
  kind?: 'account' | 'profile'
}

interface BaseCategory {
  category: string
  match?: (key: string) => boolean
  label?: (key: string) => string
  items?: FilterItem[]
  app?: (apps: App[]) => App | undefined
  icon?: TablerIcon | string
  description?: (kind: 'profile' | 'account') => React.ReactNode
  restricted?: (entitlements?: IndexingEntitlements) => boolean
}

export interface Category {
  category: string
  icon?: TablerIcon | string
  items: FilterItem[]
  description?: (kind: 'profile' | 'account') => React.ReactNode
  restricted?: (entitlements?: IndexingEntitlements) => boolean
}

export function allowCRMFields(entitlements?: IndexingEntitlements) {
  return (entitlements?.advanced_crm_filters && entitlements?.dedicated_index) ?? false
}

export function restrictCRMFields(entitlements?: IndexingEntitlements) {
  return !allowCRMFields(entitlements)
}

function hasMatcher(category: unknown): category is BaseCategory & { match: (key: string) => boolean } {
  return typeof (category as any)?.match === 'function'
}

function getAppIcon(category: BaseCategory | undefined, apps: App[]): TablerIcon | string | undefined {
  if (category?.icon) {
    return category.icon
  }

  if (typeof category?.app === 'function') {
    return category.app(apps)?.logo
  }
}

// abbreviated category names for notifications
export function shortCategory(category?: string) {
  switch (category) {
    case 'Company Attributes':
    case 'User Attributes':
    case 'Engagement':
    case 'Lists & Uploads':
    case 'Territory':
      return ''
    case 'Clearbit Attributes':
      return 'Clearbit'
    case 'Account Traits':
    case 'Profile Traits':
      return 'Traits'
    default:
      return category
  }
}

const datatypeRegex =
  /__c|(?<!object)_id|_datetime|_date|_string|_picklist|_multipicklist|_boolean|_int|_double|_currency|_text|_real|_boolean|_fixed|_address/gi

export const baseCategories: BaseCategory[] = [
  {
    category: 'Company Attributes',
    icon: IconBriefcase,
    label: (key: string) => {
      const parts = key.split('.')
      return humanize(parts[parts.length - 1])
    },
    items: [
      {
        key: 'auto_icp_account_score.fit_grade_letter',
        label: 'Firmographic Fit',
        icon: IconStar,
        values: {
          A: 0,
          B: 0,
          C: 0,
          D: 0
        }
      },
      {
        key: 'company',
        label: 'Company',
        icon: IconBuilding,
        // only support exists/not_exists
        operators: ['exists', 'not_exists'],
        kind: 'profile'
      },
      {
        key: 'company.category.industry',
        label: 'Industry',
        icon: IconBriefcase
      },
      {
        key: 'company.geo.region',
        label: 'Company HQ Region',
        icon: IconBuilding
      },
      {
        key: 'company.geo.country',
        label: 'Company HQ Country',
        icon: IconBuilding
      },
      {
        key: 'company.metrics.employeesRange',
        label: 'Employee Range',
        icon: IconHash
      },
      {
        key: 'company.metrics.employees',
        label: 'Employee Count',
        icon: IconHash
      },
      {
        key: 'company.metrics.estimatedAnnualRevenue',
        label: 'Estimated Annual Revenue',
        icon: IconCoin
      },
      {
        key: 'company.metrics.annualRevenue',
        label: 'Annual Revenue',
        icon: IconCoin
      },
      {
        key: 'company.metrics.raised',
        label: 'Amount Raised',
        icon: IconCoin
      },
      {
        key: 'company.tech',
        label: 'Tech',
        icon: IconStack2
      },
      {
        key: 'company.tech_range',
        label: 'Number of Tech Tools',
        icon: IconStack2
      },
      {
        key: 'company.category.industryGroup',
        label: 'Industry Group',
        icon: IconBriefcase
      },
      {
        key: 'company.category.sector',
        label: 'Sector',
        icon: IconBriefcase
      },
      {
        key: 'company.category.subIndustry',
        label: 'Sub Industry',
        icon: IconBriefcase
      },
      {
        key: 'company.geo.city',
        label: 'Company HQ City',
        icon: IconBuilding
      },
      {
        key: 'company.geo.state',
        label: 'Company HQ State',
        icon: IconBuilding
      },
      {
        key: 'company.geo.countryCode',
        label: 'Company HQ Country Code',
        icon: IconBuilding
      },
      {
        key: 'company.geo.stateCode',
        label: 'Company HQ State Code',
        icon: IconBuilding
      },
      {
        key: 'company.tech_categories',
        label: 'Tech Categories',
        icon: IconStack2
      },
      {
        key: 'company.tags',
        label: 'Tags',
        icon: IconTags
      },
      {
        key: 'company.founded_year',
        label: 'Year Founded',
        icon: IconHash
      },
      {
        key: 'company.domain',
        label: 'Domain',
        icon: IconWorldWww
      },
      {
        key: 'tld',
        label: 'Domain TLD',
        icon: IconWorldWww,
        kind: 'account'
      },
      {
        key: 'company.linkedin_url',
        label: 'LinkedIn (Company)',
        icon: IconBrandLinkedin
      },
      {
        key: 'company.description',
        label: 'Company Description',
        icon: IconBriefcase
      },
      {
        key: 'claimed',
        label: 'Claimed',
        kind: 'account',
        icon: IconUserSquare
      },
      {
        key: 'claimed_by',
        label: 'Claimed By',
        kind: 'account',
        icon: IconUserSquare
      }
    ]
  },
  {
    category: 'Engagement',
    description: () => 'Filter by engagement and intent signals from your website or app.',
    icon: IconBolt,
    items: [
      {
        key: 'auto_icp_account_score.intent_score_level',
        label: 'Intent Score',
        icon: IconFlame,
        kind: 'account',
        values: {
          'Very High': 0,
          High: 0,
          Medium: 0,
          Low: 0
        }
      },
      {
        key: 'intent.trend_14d',
        label: 'Intent Trend',
        kind: 'account',
        icon: IconTrendingUp,
        values: {
          New: 0,
          Surging: 0,
          Heating: 0,
          Neutral: 0,
          Cooling: 0
        }
      },
      {
        key: 'sources',
        label: 'Source',
        icon: IconPackageExport
      },
      {
        key: 'initial_source',
        label: 'Initial Source',
        icon: IconTrianglePlus,
        kind: 'profile'
      },
      {
        key: 'signal.name',
        label: 'Intent Signals',
        icon: IconBolt
      },
      {
        key: 'uniq_page_views',
        label: 'Visited Page',
        icon: IconWorldWww,
        allowFreeEntry: true,
        allowSubstringOperators: true
      },
      {
        key: 'page_hosts.host',
        label: 'Page Host',
        icon: IconWorldWww,
        allowFreeEntry: true
      },
      {
        key: 'page_views_trend.month.current.value',
        label: 'Page Views (30d)',
        icon: IconHash,
        type: 'long',
        kind: 'profile'
      },
      {
        key: 'focus_time',
        label: 'Active Session Time',
        icon: IconClockPlay,
        type: 'duration'
      },
      {
        key: 'uniq_events',
        label: 'Events',
        icon: IconClick,
        allowSubstringOperators: true
      },
      {
        key: 'form_fills.page_path',
        label: 'Form Fills',
        icon: IconForms,
        allowSubstringOperators: true
      },
      {
        key: 'referrer_activity.key',
        label: 'Referrers',
        icon: IconCornerDownRight
      },
      {
        key: 'utm_activity.key',
        label: 'UTMs',
        icon: IconCornerDownRight
      },
      {
        key: 'last_seen_at',
        label: 'Last Seen',
        icon: IconCalendar
      },
      {
        key: 'first_seen_at',
        label: 'First Seen',
        icon: IconCalendarTime
      }
    ]
  },
  {
    category: 'Territory',
    icon: IconMapSearch,
    match: (key: string) => key === 'territory'
  },
  {
    category: 'Lists & Uploads',
    icon: IconList,
    items: [
      {
        key: 'lists.name',
        label: 'List',
        icon: IconList
      },
      {
        key: 'import_lists.slug',
        label: 'CSV Upload',
        icon: IconFileTypeCsv
      }
    ]
  },
  {
    category: 'Active Visitors',
    description: () =>
      'Filter by the number of active visitors on your website. Each filter is applied at the Account level.',
    icon: IconUser,
    items: [
      {
        key: 'visitor_stats.identified.month',
        label: 'Monthly Visitors w/ Email',
        icon: IconUserCheck,
        type: 'range_stats',
        kind: 'account'
      },
      {
        key: 'visitor_stats.identified.week',
        label: 'Weekly Visitors w/ Email',
        icon: IconUserCheck,
        type: 'range_stats',
        kind: 'account'
      },
      {
        key: 'visitor_stats.identified.day',
        label: 'Daily Visitors w/ Email',
        icon: IconUserCheck,
        type: 'range_stats',
        kind: 'account'
      },
      {
        key: 'visitor_stats.visitors.month',
        label: 'Monthly Visitors',
        icon: IconUsers,
        type: 'range_stats',
        kind: 'account'
      },
      {
        key: 'visitor_stats.visitors.week',
        label: 'Weekly Visitors',
        icon: IconUsers,
        type: 'range_stats',
        kind: 'account'
      },
      {
        key: 'visitor_stats.visitors.day',
        label: 'Daily Visitors',
        icon: IconUsers,
        type: 'range_stats',
        kind: 'account'
      }
    ]
  },
  {
    category: 'User Attributes',
    description: (kind) => {
      if (kind === 'account') {
        return 'Filter by visitor specific attributes in a given account. A filter will match if it is true for any visitor in the Account.'
      }

      return 'Filter by user attributes that are automatically collected by Koala.'
    },
    icon: IconUser,
    items: [
      {
        key: 'email',
        label: 'Email',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'identified',
        label: 'Identified Email',
        type: 'boolean',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'work_email',
        label: 'Work Email',
        type: 'boolean',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'academic_email',
        label: 'Student Email',
        type: 'boolean',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'personal_email',
        label: 'Personal Email',
        type: 'boolean',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'email_type',
        label: 'Email Type',
        kind: 'profile',
        icon: IconAt
      },
      {
        key: 'identified',
        label: 'Account Includes Emails',
        type: 'boolean',
        kind: 'account',
        icon: IconAt
      },
      {
        key: 'work_email',
        label: 'Account Includes Work Emails',
        type: 'boolean',
        kind: 'account',
        icon: IconAt
      },
      {
        key: 'academic_email',
        label: 'Account Includes Student Emails',
        type: 'boolean',
        kind: 'account',
        icon: IconAt
      },
      {
        key: 'domain',
        label: 'Email Domain',
        icon: IconMail,
        kind: 'profile'
      },
      {
        key: 'tld',
        label: 'Email Domain TLD',
        icon: IconWorldWww,
        kind: 'profile'
      },
      {
        key: 'title',
        label: 'Title',
        icon: IconBriefcase,
        kind: 'profile'
      },
      {
        key: 'name',
        label: 'Name',
        icon: IconUser,
        kind: 'profile'
      },
      {
        key: 'linkedin_url',
        label: 'LinkedIn (Profile)',
        icon: IconBrandLinkedin,
        kind: 'profile'
      },
      {
        key: 'twitter_url',
        label: 'Twitter',
        icon: IconBrandTwitter,
        kind: 'profile'
      },
      {
        key: 'geo.city',
        label: 'Visitor Geo City',
        icon: IconMapPin,
        kind: 'profile'
      },
      {
        key: 'geo.state',
        label: 'Visitor Geo State',
        icon: IconMapPin,
        kind: 'profile'
      },
      {
        key: 'geo.countryCode',
        label: 'Visitor Geo Country Code',
        icon: IconMapPin,
        kind: 'profile'
      },
      {
        key: 'geo.country',
        label: 'Visitor Geo Country',
        icon: IconMapPin,
        kind: 'profile'
      }
    ]
  },
  {
    description: () => {
      return (
        <>
          Filter by Account Traits. You can learn more about account traits{' '}
          <Link
            href="https://getkoala.com/docs/admin-guides/advanced-instrumentation#setting-up-account-traits-alpha"
            isExternal
          >
            in our docs
          </Link>
          .
        </>
      )
    },
    category: 'Account Traits',
    match: (key: string) => key.includes('account_traits') && key.endsWith('.value'),
    label: (key: string) =>
      humanize(key.replace(/(account|profile)_traits_values\./gi, '').replace('value', '')).replace(datatypeRegex, ''),
    icon: IconTransform
  },
  {
    category: 'Profile Traits',
    description: (kind) => {
      if (kind === 'account') {
        return (
          <>
            Filter by{' '}
            <Link href="https://getkoala.com/docs/sdk/identify#setting-visitor-traits" isExternal>
              Profile Traits
            </Link>
            . A filter will match if it is true for any people in the Account.
          </>
        )
      }

      return (
        <>
          Filter by Profile Traits that are sent via Identify Calls. You can learn more about Profile Traits{' '}
          <Link href="https://getkoala.com/docs/sdk/identify#setting-visitor-traits" isExternal>
            in our docs
          </Link>
          .
        </>
      )
    },
    match: (key: string) => key.includes('profile_traits') && key.endsWith('.value'),
    label: (key: string) =>
      humanize(key.replace(/(account|profile)_traits_values\./gi, '').replace('value', '')).replace(datatypeRegex, ''),
    icon: IconTransform
  }
]

const clearbit: BaseCategory[] = [
  {
    category: 'Clearbit Attributes',
    description: () => 'These are the fields returned by Clearbit Reveal when an IP address is matched to a company.',
    app: (apps: App[]) => apps.find((app) => app?.title === 'Clearbit'),
    icon: 'https://logo.clearbit.com/clearbit.com',
    match: (key: string) =>
      key.startsWith('clearbit.') && !['clearbit.company.clearbit_id', 'clearbit.company.logo'].includes(key),
    label: (key: string) => {
      const parts = key.split('.')
      return humanize(parts[parts.length - 1])
    }
  }
]

const hubspot: BaseCategory[] = [
  {
    category: 'HubSpot Company',
    description: () =>
      'Filter by any attributes that are present in the Company record associated with the Account or Visitor.',
    app: (apps: App[]) => apps.find((app) => app?.title === 'HubSpot'),
    icon: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg',
    match: (key: string) =>
      key.startsWith('hubspot_data') && !key.startsWith('hubspot_data.deals') && !key.includes('has_engaged'),
    label: (key: string) => humanize(key.replace(/hubspot_data\.|crm_fields\./gi, '').replace(datatypeRegex, '')),
    restricted: restrictCRMFields,
    items: [
      {
        key: 'hubspot_data.in_crm',
        label: 'Company in HubSpot',
        type: 'boolean'
      },
      {
        key: 'hubspot_data.account_owner',
        label: 'Company Owner'
      },
      {
        key: 'hubspot_data.account_owner_email',
        label: 'Company Owner Email'
      },
      {
        key: 'hubspot_data.account_type',
        label: 'Company Type'
      }
    ]
  },
  {
    category: 'HubSpot Contact',
    restricted: restrictCRMFields,
    description: () => 'Filter by any attributes that are present in the Contact record associated with the Visitor.',
    match: (key: string) => key.startsWith('hubspot_contact_data'),
    label: (key: string) => humanize(key.replace(/hubspot_contact_data.|fields\./gi, '').replace(datatypeRegex, '')),
    app: (apps: App[]) => apps.find((app) => app?.title === 'HubSpot'),
    icon: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg'
  },
  {
    category: 'HubSpot Deal',
    restricted: restrictCRMFields,
    description: () =>
      'Filter by any attributes that are present in the Deal record associated with the Visitor or the Account.',
    match: (key: string) => key.startsWith('hubspot_data.deals'),
    label: (key: string) =>
      humanize(key.replace(/hubspot_data\.|crm_fields\./gi, '').replace(datatypeRegex, '')).replace('Deals ', ''),
    app: (apps: App[]) => apps.find((app) => app?.title === 'HubSpot'),
    icon: 'https://cdn.cdnlogo.com/logos/h/24/hubspot.svg'
  }
]

const salesforce: BaseCategory[] = [
  {
    category: 'Salesforce Account',
    description: () =>
      'Filter by any attributes that are present in the Account record associated with the Visitor or the Account.',
    app: (apps: App[]) => apps.find((app) => app?.title === 'Salesforce'),
    icon: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg',
    match: (key: string) =>
      key.startsWith('salesforce_data') && !key.includes('.opportunities.') && !key.includes('has_engaged'),
    label: (key: string) => humanize(key.replace(/salesforce_data\.|crm_fields\./gi, '').replace(datatypeRegex, '')),
    restricted: restrictCRMFields,
    items: [
      {
        key: 'salesforce_data.in_crm',
        label: 'Account in Salesforce',
        type: 'boolean'
      },
      {
        key: 'salesforce_data.account_owner',
        label: 'Account Owner'
      },
      {
        key: 'salesforce_data.account_owner_email',
        label: 'Account Owner Email'
      },
      {
        key: 'salesforce_data.account_type',
        label: 'Account Type'
      }
    ]
  },
  {
    category: 'Salesforce Contact',
    restricted: restrictCRMFields,
    description: () => 'Filter by any attributes that are present in the Contact record associated with the Visitor.',
    match: (key: string) => key.startsWith('salesforce_contact_data'),
    label: (key: string) =>
      `Contact ${humanize(key.replace(/salesforce_contact_data\.|fields\./gi, '').replace(datatypeRegex, '')).replace(
        'Contact ',
        ''
      )}`,
    app: (apps: App[]) => apps.find((app) => app?.title === 'Salesforce'),
    icon: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg'
  },
  {
    category: 'Salesforce Lead',
    restricted: restrictCRMFields,
    description: () => 'Filter by any attributes that are present in the Lead record associated with the Visitor.',
    match: (key: string) => key.startsWith('salesforce_lead_data'),
    label: (key: string) =>
      `Lead ${humanize(key.replace(/salesforce_lead_data\.|fields\./gi, '').replace(datatypeRegex, '')).replace(
        'Lead ',
        ''
      )}`,
    app: (apps: App[]) => apps.find((app) => app?.title === 'Salesforce'),
    icon: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg'
  },
  {
    category: 'Salesforce Opportunity',
    restricted: restrictCRMFields,
    items: [
      {
        key: 'salesforce_opportunity.opportunities.OwnerId_ref.Name',
        label: 'Opportunity Owner'
      },
      {
        key: 'salesforce_opportunity.opportunities.OwnerId_ref.Email',
        label: 'Opportunity Owner Email'
      }
    ],
    description: () => {
      return (
        <>
          Filter by any attributes that are present in any Opportunity records associated with the Visitor or Account.
        </>
      )
    },
    match: (key: string) => key.startsWith('salesforce_opportunity'),
    label: (key: string) =>
      humanize(key.replace(/salesforce_opportunity\.|crm_fields\./gi, '').replace(datatypeRegex, '')).replace(
        'Opportunities ',
        ''
      ),
    app: (apps: App[]) => apps.find((app) => app?.title === 'Salesforce'),
    icon: 'https://cdn.cdnlogo.com/logos/s/3/salesforce.svg'
  }
]

const g2: BaseCategory[] = [
  {
    category: 'G2',
    app: (apps: App[]) => apps.find((app) => app?.title === 'G2'),
    icon: 'https://logo.clearbit.com/g2.com',
    label: (key: string) => humanize(key.replace(/g2\./gi, '').replace(datatypeRegex, '')),
    items: [
      {
        key: 'g2.events',
        label: 'G2 Activity',
        type: 'keyword'
      },
      {
        key: 'g2.num_interactions',
        label: '# G2 Activity',
        type: 'long'
      }
    ]
  }
]

const github: BaseCategory[] = [
  {
    category: 'GitHub',
    description: () => 'Filter by GitHub-related attributes associated with the Account or Visitor.',
    app: (apps: App[]) => apps.find((app) => app?.title === 'GitHub'),
    icon: 'https://logo.clearbit.com/github.com',
    match: (key: string) => key.startsWith('github.'),
    label: (key: string) => humanize(key.replace(/github\./gi, '').replace(datatypeRegex, '')),
    items: [
      {
        key: 'github.last_activity_at',
        label: 'Last Activity At',
        type: 'date'
      },
      {
        key: 'github.first_activity_at',
        label: 'First Activity At',
        type: 'date'
      },
      {
        key: 'github.events',
        label: 'Repo Interactions',
        type: 'keyword'
      },
      {
        key: 'github.num_interactions',
        label: '# Repo Interactions',
        type: 'long'
      },
      {
        key: 'github',
        label: 'Tracked in GitHub',
        operators: ['exists', 'not_exists']
      }
    ]
  }
]

const slack: BaseCategory[] = [
  {
    category: 'Slack',
    description: () => 'Filter by Slack-related attributes associated with the Account or Visitor.',
    app: (apps: App[]) => apps.find((app) => app?.title === 'Slack Community'),
    icon: 'https://logo.clearbit.com/slack.com',
    match: (key: string) => key.startsWith('slack'),
    label: (key: string) => humanize(key.replace(/slack\./gi, '').replace(datatypeRegex, '')),
    items: [
      {
        key: 'slack.last_activity_at',
        label: 'Last Activity At',
        type: 'date'
      },
      {
        key: 'slack.first_activity_at',
        label: 'First Activity At',
        type: 'date'
      },
      {
        key: 'slack.events',
        label: 'Channel Interactions',
        type: 'keyword'
      },
      {
        key: 'slack.num_interactions',
        label: '# Channel Interactions',
        type: 'long'
      },
      {
        key: 'slack',
        label: 'Tracked in Slack',
        operators: ['exists', 'not_exists']
      }
    ]
  }
]

const linkedin: BaseCategory[] = [
  {
    category: 'LinkedIn',
    description: () => 'Filter by LinkedIn-related attributes associated with the Account or Visitor.',
    app: (apps: App[]) => apps.find((app) => app?.title?.toLowerCase()?.includes('linkedin')),
    icon: 'https://logo.clearbit.com/linkedin.com',
    match: (key: string) => key.startsWith('linkedin'),
    label: (key: string) => humanize(key.replace(/linkedin\./gi, '').replace(datatypeRegex, '')),
    items: [
      {
        key: 'linkedin.last_activity_at',
        label: 'Last Activity At',
        type: 'date'
      },
      {
        key: 'linkedin.first_activity_at',
        label: 'First Activity At',
        type: 'date'
      },
      {
        key: 'linkedin.events',
        label: 'Post Interactions',
        type: 'keyword'
      },
      {
        key: 'linkedin.num_interactions',
        label: '# Post Interactions',
        type: 'long'
      },
      {
        key: 'linkedin',
        label: 'Tracked on LinkedIn',
        operators: ['exists', 'not_exists']
      }
    ]
  }
]

const allCategories = flatten(baseCategories.concat(clearbit, salesforce, hubspot, github, slack, linkedin, g2))

export function categoryGroupings(categories: Category[]) {
  const grouped = groupBy(categories, (category) => {
    const isBaseCategory = baseCategories.some((c) => c.category == category.category)

    if (isBaseCategory) {
      return 'Categories'
    } else {
      return 'Integrations'
    }
  })

  // Group into top-level categories with sub-categories
  return Object.entries(grouped).map(([title, subcategories]) => {
    return { title, subcategories }
  })
}

function getCategories(apps: App[]): BaseCategory[] {
  const categories = Array.from(baseCategories)
  apps = apps.filter((app) => app?.title)

  if (apps.find((app) => app.title === 'Clearbit')) {
    categories.push(...clearbit)
  }

  if (apps.find((app) => app.title === 'Salesforce')) {
    categories.push(...salesforce)
  }

  if (apps.find((app) => app.title === 'HubSpot')) {
    categories.push(...hubspot)
  }

  if (apps.find((app) => app.title === 'GitHub')) {
    categories.push(...github)
  }

  if (apps.find((app) => app.title === 'Slack Community')) {
    categories.push(...slack)
  }

  if (apps.find((app) => app.title.toLowerCase().includes('linkedin'))) {
    categories.push(...linkedin)
  }

  if (apps.find((app) => app.title === 'G2')) {
    categories.push(...g2)
  }

  return categories
}

function getCategory(facet: string): BaseCategory | undefined {
  return allCategories.find((c) => {
    let isMatch = c.items?.some((item) => item.key === facet)

    if (!isMatch && hasMatcher(c)) {
      isMatch = c.match(facet)
    }

    return isMatch
  })
}

const emptyObject = {}

export function getTypeIcon(type?: string | undefined | null) {
  switch (type) {
    case 'boolean':
      return IconToggleLeft
    case 'currency':
      return IconCoin
    case 'date':
      return IconCalendarMonth
    case 'float':
    case 'double':
    case 'number':
    case 'long':
      return IconHash
    case 'keyword':
    case 'string':
    case 'ip':
      return TextIcon
    default:
      return undefined
  }
}

export function getItemDisplay(
  facet: string,
  apps: any[],
  kind?: 'profile' | 'account',
  mapping?: Partial<FacetMapping>
): FilterItem & { category?: string; appIcon?: string } {
  const category = getCategory(facet)
  const labelFmt = typeof category?.label === 'function' ? category.label : humanize

  const item = category?.items?.find((item) => {
    if (kind && item.kind && item.kind !== kind) {
      return false
    }

    return item.key === facet
  })

  const appIcon = getAppIcon(category, apps) as string // this is a lie but its ok
  const icon = item?.icon ?? category?.icon ?? appIcon ?? getTypeIcon(item?.type)
  const label = item?.label || mapping?.label || labelFmt(facet)

  return {
    key: facet,
    label,
    category: category?.category,
    icon,
    appIcon,
    values: item?.values ?? emptyObject
  }
}

export function grouped(
  facets: FacetMappings,
  apps: App[],
  allowedKeys?: string[],
  excludedKeys?: string[]
): Category[] {
  const keys = Object.keys(facets)
  // make sure categories are a deep copy
  // otherwise we may end up mutating the original categories object.
  // Mutating the categories object causes facet cloud numbers to
  // no longer match.
  const categories = cloneDeep(getCategories(apps))

  return categories
    .map((c) => {
      const icon = c.icon ?? getAppIcon(c, apps)
      let items = c.items ?? []

      if (allowedKeys?.length) {
        items = items.filter((i) => allowedKeys.includes(i.key))
      } else if (excludedKeys?.length) {
        items = items.filter((i) => !excludedKeys.includes(i.key))
      }

      // Each category has all items explicitly defined by key
      // and any items in the facet cloud that match
      // based on the category's match function
      if (hasMatcher(c)) {
        const labelFmt = typeof c.label === 'function' ? c.label : humanize

        for (const key of keys) {
          // Skip keys that are already explicitly defined or dont match
          if (!c.match(key) || items.some((item) => item.key === key)) {
            continue
          }

          const facet = facets[key] || {}

          items.push({
            key,
            label: facet.label || labelFmt(key)
          })
        }
      }

      // Ensure that we have an icon for each item, either defined by the item or the category
      // Copy over the options + counts from the facet cloud
      for (const item of items) {
        const facet = facets[item.key] || {}

        if (!item.type) {
          item.type = facet.type
        }

        if (!item.icon) {
          // derive icon from type, or fallback to category icon
          const typeIcon = c.category.includes('Traits') ? getTypeIcon(item.type) : undefined
          item.icon = typeIcon || icon
        }

        if (!item.id) {
          item.id = item.key
        }

        if (!item.category) {
          item.category = shortCategory(c.category)
        }

        const values = facet.values || {}
        item.values = { ...item.values, ...values }
      }

      return {
        category: c.category,
        icon,
        items,
        description: c.description,
        restricted: c.restricted
      }
    })
    .filter((c) => c.items.length > 0)
}

const allItems = allCategories.flatMap((c) => c.items ?? [])

// filter is a string of the form  "facets.nested.key"
export function validFilter(kind: string, filter: string | string[]): boolean {
  const filters = Array.isArray(filter) ? filter : [filter]

  const item = allItems.find((item) => filters.includes(item.key))
  if (!item) {
    return true
  }

  if (item) {
    return item?.kind === undefined || item.kind === kind
  }

  // return true if this is a dynamic filter
  return true
}
