import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import {
  Box,
  Button,
  Spacer,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Icon
} from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import { Apps } from '../../types/App'
import { ProfileRecord } from '../../types/Profile'
import { Prospect } from '../data/use-prospects'
import { usePermission } from './PermissionsContext'
import { AddToApolloSequenceModal } from './AddToApolloSequenceModal'
import { AddToOutreachSequenceModal } from './AddToOutreachSequenceModal'
import { AddToSalesloftCadenceModal } from './AddToSalesloftCadenceModal'
import { AddToInstantlyCampaignModal } from './AddToInstantlyCampaignModal'

import React, { useMemo } from 'react'

interface BulkAddToSequenceProps {
  apps: Apps
  selectedRecords: ProfileRecord[] | Prospect[] | []
  recordType?: 'Profile' | 'ProspectedProfile'
  recordLabel?: 'people' | 'contacts' | 'prospects'
  skipAnonymousProfiles?: boolean | false
  onComplete?: (records: any[]) => void
}

export function BulkAddToSequenceMenu(props: BulkAddToSequenceProps) {
  const { hasPermission: canManageAccounts } = usePermission({ on: 'project', action: 'can_manage_accounts' })

  const addToApollo = useDisclosure()
  const addToOutreach = useDisclosure()
  const addToSalesloft = useDisclosure()
  const addToInstantly = useDisclosure()

  const availableSequences = useMemo(() => {
    const available = {}

    for (const app of Object.values(props.apps || {})) {
      if (!(app.valid || app.client_valid)) continue

      if (app.app_module === 'Apps::Instantly::App') {
        available['Instantly'] = { modal: addToInstantly, label: 'Add to Instantly campaign' }
      }
      if (app.app_module === 'Apps::Apollo::App') {
        available['Apollo'] = { modal: addToApollo, label: 'Add to Apollo sequence' }
      }
      if (app.app_module === 'Apps::Outreach::App') {
        available['Outreach'] = { modal: addToOutreach, label: 'Add to Outreach sequence' }
      }
      if (app.app_module === 'Apps::Salesloft::App') {
        available['Salesloft'] = { modal: addToSalesloft, label: 'Add to Salesloft cadence' }
      }
    }

    return available
  }, [props.apps, addToInstantly, addToApollo, addToOutreach, addToSalesloft])

  const availableSequenceNames = Object.keys(availableSequences)
  const hasInstantly = availableSequenceNames.includes('Instantly')
  const hasSalesloft = availableSequenceNames.includes('Salesloft')
  const hasOutreach = availableSequenceNames.includes('Outreach')
  const hasApollo = availableSequenceNames.includes('Apollo')

  return (
    <Menu>
      {availableSequenceNames.length == 1 && (
        <MenuButton
          as={Button}
          size="sm"
          variant="outline"
          isDisabled={!canManageAccounts}
          onClick={availableSequences[availableSequenceNames[0]].modal.onOpen}
        >
          {availableSequences[availableSequenceNames[0]].label}
        </MenuButton>
      )}
      {availableSequenceNames.length > 1 && (
        <>
          <MenuButton as={Button} size="sm" variant="outline" isDisabled={!canManageAccounts}>
            Add to Sequence
          </MenuButton>
          <MenuList>
            <Box p={2}>
              <Text fontSize="sm" fontWeight="bold" mb={2}>
                Choose the sequence tool
              </Text>
              {hasSalesloft && (
                <MenuItem onClick={addToSalesloft.onOpen}>
                  <Flex width="100%" alignItems="center">
                    <CompanyAvatar
                      size="14px"
                      mr={2}
                      domain={'salesloft.com'}
                      src="https://asset.brandfetch.io/idLO9lFZj5/id8bueqGAT.jpeg"
                    />
                    <Text>Salesloft</Text>
                    <Spacer />
                    <Icon as={IconChevronRight} color="purple.500" boxSize={4} />
                  </Flex>
                </MenuItem>
              )}
              {hasInstantly && (
                <MenuItem onClick={addToInstantly.onOpen} isDisabled={true}>
                  <Flex width="100%" alignItems="center">
                    <CompanyAvatar
                      size="14px"
                      mr={2}
                      domain={'instantly.ai'}
                      src={props.apps?.['Apps::Instantly::App'].logo}
                    />
                    <Text>Instantly</Text>
                    <Spacer />
                    <Icon as={IconChevronRight} color="purple.500" boxSize={4} />
                  </Flex>
                </MenuItem>
              )}
              {hasOutreach && (
                <MenuItem onClick={addToOutreach.onOpen}>
                  <Flex width="100%" alignItems="center">
                    <CompanyAvatar
                      size="14px"
                      mr={2}
                      domain={'outreach.io'}
                      src={props.apps?.['Apps::Outreach::App'].logo}
                    />
                    <Text>Outreach</Text>
                    <Spacer />
                    <Icon as={IconChevronRight} color="purple.500" boxSize={4} />
                  </Flex>
                </MenuItem>
              )}
              {hasApollo && (
                <MenuItem onClick={addToApollo.onOpen}>
                  <Flex width="100%" alignItems="center">
                    <CompanyAvatar
                      size="14px"
                      mr={2}
                      domain={'apollo.io'}
                      src={'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png'}
                    />
                    <Text>Apollo</Text>
                    <Spacer />
                    <Icon as={IconChevronRight} color="purple.500" boxSize={4} />
                  </Flex>
                </MenuItem>
              )}
            </Box>
          </MenuList>
        </>
      )}

      {hasSalesloft && (
        <AddToSalesloftCadenceModal
          {...addToSalesloft}
          app={props.apps!['Apps::Salesloft::App']}
          selectedProfiles={props.selectedRecords}
          profileType={props.recordType}
          profileLabel={props.recordLabel}
          skipAnonymousProfiles={props.skipAnonymousProfiles}
        />
      )}
      {hasInstantly && (
        <AddToInstantlyCampaignModal
          {...addToInstantly}
          app={props.apps!['Apps::Instantly::App']}
          selectedProfiles={props.selectedRecords}
          profileType={props.recordType}
          profileLabel={props.recordLabel}
          skipAnonymousProfiles={props.skipAnonymousProfiles}
        />
      )}
      {hasOutreach && (
        <AddToOutreachSequenceModal
          {...addToOutreach}
          app={props.apps!['Apps::Outreach::App']}
          selectedProfiles={props.selectedRecords}
          profileType={props.recordType}
          profileLabel={props.recordLabel}
          skipAnonymousProfiles={props.skipAnonymousProfiles}
        />
      )}
      {hasApollo && (
        <AddToApolloSequenceModal
          {...addToApollo}
          app={props.apps!['Apps::Apollo::App']}
          selectedProfiles={props.selectedRecords}
          profileType={props.recordType}
          profileLabel={props.recordLabel}
          skipAnonymousProfiles={props.skipAnonymousProfiles}
        />
      )}
    </Menu>
  )
}
