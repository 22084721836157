import { Box, Heading } from '@chakra-ui/react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import React from 'react'
import { Bucket } from './types'

interface MiniScoreDistributionProps {
  buckets: Bucket[]
  pointsHistogram: {
    key: number
    doc_count: number
  }[]
  totalPossiblePoints: number
}

export default function MiniScoreDistribution({
  buckets,
  pointsHistogram,
  totalPossiblePoints
}: MiniScoreDistributionProps) {
  return (
    <Box padding={4} border="1px solid" borderColor="gray.200" borderRadius="md">
      <Heading size="sm" mb={4}>
        Score Distribution
      </Heading>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={pointsHistogram}
          margin={{
            top: 30,
            right: 40,
            left: 40,
            bottom: 40
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="key"
            label={{ value: 'Points', position: 'bottom' }}
            padding={{ left: 100, right: 10 }}
            reversed
            type="number"
            scale={'sequential'}
            domain={[-0.5, totalPossiblePoints + 0.5]}
          />
          <YAxis label={{ value: 'Accounts', angle: -90, position: 'left' }} dataKey="doc_count" domain={[0, 'auto']} />

          {buckets.map((bucket, index) => (
            <ReferenceLine key={`line-${index}`} x={bucket.from - 0.5} stroke="gray" strokeDasharray="3 3" />
          ))}

          {buckets.map((bucket, index) => (
            <ReferenceArea
              key={`dot-${index}`}
              x1={bucket.from - 0.4}
              x2={(bucket.to === undefined ? totalPossiblePoints : bucket.to - 1) + 0.4}
              y={10}
              r={20}
              fill="#f7fafc"
              stroke="#6b46c1"
              strokeWidth={1}
              ifOverflow={'visible'}
            >
              <Label value={bucket.key} position="top" />
            </ReferenceArea>
          ))}

          <Bar dataKey="doc_count" fill="#6b46c1">
            <LabelList dataKey="doc_count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
