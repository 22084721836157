import {
  Code,
  Heading,
  HStack,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import { DownloadCSVButton, PageViewEntry } from '.'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { titleize } from '../accounts/facets/filter-cloud'
import DateRangePicker from '../analytics/components/DateRangePicker'
import { mergeParams } from '../icps/types'
import { accountDomainPath } from '../accounts/lib/account-path'
import { DownloadCsvIcon, DownloadCsvTooltip } from '../../ui/DownloadCsvButtons'

interface Props {
  entries: PageViewEntry[]
  property: string
  value: string
  page_meta: PageMeta
  period: 'day' | 'week' | 'month'
}

export default function UtmValue(props: Props) {
  const project = useCurrentProject()

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb
        paths={[
          { title: 'UTM Reports', path: projectPath('/utm-reports') },
          {
            title: titleize(props.property),
            path: projectPath(`/utm-reports/utm/${props.property}`)
          },
          {
            title: props.value,
            path: projectPath(`/utm-reports/utm/${props.property}/${props.value}`)
          }
        ]}
      />

      <HStack w="100%" justifyContent={'space-between'} alignItems="flex-start">
        <PageTitle>
          {props.property} = {props.value}
        </PageTitle>

        <HStack>
          <DateRangePicker
            period={props.period}
            onChange={(period) => {
              router.visit(
                mergeParams(window.location.toString(), {
                  period: period,
                  page: '1'
                })
              )
            }}
          />

          <DownloadCSVButton
            basePath={projectPath(`/utm-reports/utm/${props.property}/${props.value}.csv`)}
            pageMeta={props.page_meta}
            title={
              <Heading size="sm">
                Recent Page Views for{' '}
                <Code fontWeight={'semibold'} px="2" py="1">
                  utm_{props.property}={props.value}
                </Code>
              </Heading>
            }
            description={
              <Text fontSize={'sm'}>
                This CSV contains the recent page views for {props.property} = {props.value}. CSV downloads are limited
                to the most recent 10,000 page views per download.
              </Text>
            }
            period={props.period}
            trigger={(onClick) => (
              <DownloadCsvTooltip tooltip="Download CSV">
                <IconButton
                  size="sm"
                  onClick={onClick}
                  colorScheme={'green'}
                  variant="outline"
                  isDisabled={!project?.can_export_data}
                  icon={<DownloadCsvIcon size={12} />}
                  aria-label="Download CSV"
                />
              </DownloadCsvTooltip>
            )}
          />
        </HStack>
      </HStack>

      <Stack spacing="4">
        <Heading size="xs">Recent Page Views</Heading>
        <TableContainer>
          <Table size="sm" variant={'striped'}>
            <Thead>
              <Tr>
                <Th>URL</Th>
                <Th>Account</Th>
                <Th>Account Score</Th>
                <Th>Profile Id</Th>
                <Th>Source</Th>
                <Th>Medium</Th>
                <Th>Term</Th>
                <Th>Content</Th>
                <Th>Campaign</Th>
                <Th>Referring Domain</Th>
                <Th>Timestamp</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.entries.map((entry) => (
                <Tr key={entry.id}>
                  <Td>
                    <Link href={entry.url} isExternal fontWeight={'normal'}>
                      <HStack>
                        <TextEllipsis maxW="340px" tooltip>
                          {entry.url}
                        </TextEllipsis>
                        <IconExternalLink size="12" />
                      </HStack>
                    </Link>
                  </Td>
                  <Td>
                    <Link href={accountDomainPath(entry.account_domain) ?? ''}>
                      <HStack>
                        {entry.account_domain && <CompanyAvatar domain={entry.account_domain} size="xs" />}
                        <TextEllipsis maxW="100px">{entry.account_domain}</TextEllipsis>
                      </HStack>
                    </Link>
                  </Td>
                  <Td>{entry.fit_grade_letter}</Td>
                  <Td>
                    <Link href={entry.profile_url}>{entry.email ?? entry.profile_id}</Link>
                  </Td>
                  <Td>{entry.utm_source}</Td>
                  <Td>{entry.utm_medium}</Td>
                  <Td>{entry.utm_term}</Td>
                  <Td>{entry.utm_content}</Td>
                  <Td>{entry.utm_campaign}</Td>
                  <Td>
                    <TextEllipsis tooltip maxW="150px">
                      {entry.referrer}
                    </TextEllipsis>
                  </Td>
                  <Td>
                    <TimeAgo time={entry.visit_start} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <TableFooter
          scrollToTop={false}
          pageMeta={props.page_meta}
          page={props.page_meta.current_page}
          nextPath={mergeParams(window.location.toString(), {
            page: `${props.page_meta.current_page + 1}`
          })}
          prevPath={mergeParams(window.location.toString(), {
            page: `${props.page_meta.current_page - 1}`
          })}
        />
      </Stack>
    </PageLayout>
  )
}
