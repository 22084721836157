import { Button, Flex, FormErrorMessage, Link } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { FormLabel } from '@chakra-ui/react'
import { FormControl, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { GrayCard } from '../../../ui/Card'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'

interface Props {
  ai_agent: any
  errors?: {
    [key: string]: string[]
  }
}

export function AIAgentForm(props: Props) {
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = React.useState(props.ai_agent?.name ?? '')
  const nameError = name === (props.ai_agent?.name || '') ? props.errors?.name?.join(', ') : ''

  const [instructions, setInstructions] = React.useState(props.ai_agent?.question ?? '')
  const instructionsError = instructions === (props.ai_agent?.question || '') ? props.errors?.question?.join(', ') : ''

  const { hasPermission: canEditAgents } = usePermission({ on: 'project', action: 'can_edit' })

  return (
    <form
      action={projectPath(`/settings/agents/${props.ai_agent?.id || ''}`)}
      method="POST"
      onSubmit={() => setSubmitting(true)}
    >
      <AuthenticityToken />
      {props.ai_agent?.id && <input type="hidden" name="_method" value="PATCH" />}

      <GrayCard w="100%">
        <Stack spacing={10} w="100%">
          <FormControl id="ai_agent[name]" isInvalid={!!nameError} isRequired>
            <FormLabel>Agent name</FormLabel>
            <Input
              type="text"
              bg="white"
              name="ai_agent[name]"
              placeholder="e.g. Company Products/Services"
              size="sm"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <FormErrorMessage>{nameError}</FormErrorMessage>
          </FormControl>

          <FormControl id="ai_agent[question]" isInvalid={!!instructionsError} isRequired>
            <FormLabel>Prompt</FormLabel>
            <AutosizedTextarea
              name="ai_agent[question]"
              placeholder="e.g. What are the company's products and services?"
              size="sm"
              bg="white"
              minRows={4}
              maxRows={8}
              value={instructions}
              onChange={(e) => {
                setInstructions(e.target.value)
              }}
            />
            <FormErrorMessage>{instructionsError}</FormErrorMessage>
          </FormControl>

          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap={3}
            pt={5}
            borderTop="1px solid"
            borderColor="gray.200"
          >
            <Button size="sm" variant="outline" as={Link} href={projectPath('/territories')}>
              Cancel
            </Button>

            <Button type="submit" size="sm" colorScheme="purple" isLoading={submitting} isDisabled={!canEditAgents}>
              {props.ai_agent?.id ? 'Update agent' : 'Create agent'}
            </Button>
          </Flex>
        </Stack>
      </GrayCard>
    </form>
  )
}
