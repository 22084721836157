import { Box, Center, Progress, SlideFade, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export const loadingPhrases = [
  'Analyzing the data...',
  'Crunching the numbers...',
  'Reading between the lines...',
  'Connecting the dots...',
  'Discovering insights...',
  'Processing signals...',
  'Beep boop...',
  'Generating recommendations...'
]

interface LoadingPhrasesProps {
  isLoading: boolean
}

export function LoadingPhrases({ isLoading }: LoadingPhrasesProps) {
  const [loadingPhrase, setLoadingPhrase] = useState(loadingPhrases[0])

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setLoadingPhrase((prev) => {
          const currentIndex = loadingPhrases.indexOf(prev)
          return loadingPhrases[(currentIndex + 1) % loadingPhrases.length]
        })
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [isLoading])

  if (!isLoading) return null

  return (
    <Center height="200px">
      <Stack width="60%" spacing={4}>
        <Box height="20px">
          <SlideFade
            in={true}
            key={loadingPhrase} // Key prop triggers animation on phrase change
            transition={{ enter: { duration: 0.3, ease: 'linear' } }}
            offsetY="4px"
          >
            <Text fontSize="sm" textAlign="center" color="gray.500">
              {loadingPhrase}
            </Text>
          </SlideFade>
        </Box>
        <Progress height="3px" color="gray.200" isIndeterminate rounded="full" />
      </Stack>
    </Center>
  )
}
