import React from 'react'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { AIAgentForm } from './components/AIAgentForm'

interface Props {
  ai_agent: any
  errors?: {
    [key: string]: string[]
  }
}

export default function NewAIAgent(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/settings/agents'), title: 'AI Agents' },
          { path: window.location.toString(), title: 'New Agent' }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">Create an AI agent</PageTitle>
        <PageDescription>
          AI agents can research accounts and surface key information automatically or on-demand for your team.
        </PageDescription>
      </SettingsHeader>

      <AIAgentForm {...props} />
    </PageLayout>
  )
}
