export const defaultCategoricalColors = [
  '#7467ef', // Muted Purple
  '#7F9CF5', // Muted lighter purple/blue
  '#B794F4', // Soft lilac
  '#F687B3', // Pink with a hint of purple
  '#a7dcf9', // light blue
  '#F56565', // Soft red for contrast
  '#ED8936', // Muted orange for warmth
  '#ECC94B' // Warm muted yellow
]

export function constructCategoryColors(
  categories: string[],
  colors: string[] = defaultCategoricalColors
): Map<string, string> {
  const categoryColors = new Map<string, string>()

  categories.forEach((category, idx) => {
    categoryColors.set(category, colors[idx % colors.length])
  })

  return categoryColors
}

export function constructMonochromeColors(keys: string[], colorScheme: string = 'gray'): Map<string, string> {
  const colors = new Map<string, string>()

  keys.forEach((key, idx) => {
    colors.set(key, `var(--chakra-colors-${colorScheme}-${-idx * 100 + 600})`)
  })

  return colors
}
