import { projectPath } from '@app/components/ui/ProjectsContext'
import { post } from '@app/lib/api'
import { useMutation } from 'react-query'
import { ProfileRecord } from '../../types/Profile'

interface ResponseData {
  crm_entity_id: string
  permalink: string
}
interface Params {
  domain: string
  app_module: string
}
export function useImportToCRM() {
  return useMutation(({ domain, app_module }: Params) => {
    const path = projectPath(`/accounts/${domain}/import`)
    return post<ResponseData>(path, { app_module: app_module })
  })
}

interface AccountResponseData {
  accounts: []
}
interface AccountParams {
  account_ids: string[]
  app_module: string
  type?: string
}
export function useImportAccountsToCrm() {
  return useMutation<AccountResponseData, unknown, AccountParams>((params) => {
    const path = projectPath(`/accounts/import_multiple`)
    return post(path, params)
  })
}

interface ProspectParams {
  prospect_ids: string[]
  app_module: 'Hubspot' | 'Salesforce'
  type?: 'contact' | 'lead' | undefined
}
interface ProspectResponseData {
  prospects: any[]
}
export function useImportProspectsToCrm() {
  return useMutation<ProspectResponseData, unknown, ProspectParams>((params) => {
    const path = projectPath(`/prospects/import`)
    return post(path, params)
  })
}

interface ProfileParams {
  profile_ids: string[]
  app_module: 'Hubspot' | 'Salesforce' | 'Outreach'
  type?: 'contact' | 'lead' | undefined
}
interface ProfileResponseData {
  profiles: ProfileRecord[]
}
export function useImportProfilesToCrm() {
  return useMutation<ProfileResponseData, unknown, ProfileParams>((params) => {
    const path = projectPath(`/profiles/import`)
    return post(path, params)
  })
}
