import React, { FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from './Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Icon,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { IconCircleDashed } from '@tabler/icons-react'
import { postForm } from '../../lib/api'
import { Prospect } from '../data/use-prospects'
import { useAppDep } from '../data/use-app-dep'
import { ProfileRecord } from '../../types/Profile'
import { App } from '../../types/App'
import { Account } from '../../types/Account'
import { TextEllipsis } from './text-ellipsis'
import { projectPath } from './ProjectsContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'

interface AddToInstantlyProps extends UseDisclosureProps {
  app: App
  account?: Account
  selectedProfiles: Prospect[] | ProfileRecord[] | []
  profileType?: 'Profile' | 'ProspectedProfile'
  profileLabel?: string
  skipAnonymousProfiles?: boolean | false
}

interface InstantlyCampaign {
  id: string
  name: string
}

export function AddToInstantlyCampaignModal(props: AddToInstantlyProps) {
  const { selectedProfiles, skipAnonymousProfiles, profileType, profileLabel } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const [saving, setSaving] = useState(false)

  // TODO: check if we need to set any initial state here
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null)

  const { data: campaignData, isLoading: loadingCampaigns } = useAppDep<'campaigns', InstantlyCampaign[]>(
    'instantly',
    'campaigns'
  )

  const profilesWithoutEmail = (selectedProfiles as any[]).filter((p) => !p.email)
  const profilesToAdd = skipAnonymousProfiles ? (selectedProfiles as any[]).filter((p) => p.email) : selectedProfiles
  const targetLabel = profileLabel || (profileType == 'Profile' ? 'people' : 'prospects')
  const targetPluralizedLabel = pluralize(targetLabel, profilesToAdd.length, profilesToAdd.length > 1)

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      const profileIds = selectedProfiles?.map((p) => p.id) ?? []
      profileIds.forEach((profileId) => data.append('profile_ids[]', profileId))

      //TODO: find a way to get info about the campaign_id

      postForm(projectPath(`/instantly/actions/bulk-campaign?profile_type=${props.profileType ?? 'Profile'}`), data)
        .then(() => {
          toast.success(`${capitalize(targetLabel)} added to Sequence!`, {
            description: `${targetPluralizedLabel} has been added to campaign in Instantly.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error(`Error adding ${targetLabel} to sequence`, {
            description: e.message
          })
          setSaving(false)
        })
    },
    [selectedProfiles, props.profileType, onClose, targetLabel, targetPluralizedLabel]
  )

  const [showMoreProfiles, setShowMoreProfiles] = useState(false)
  const toggleShowMoreProfiles = () => {
    setShowMoreProfiles(!showMoreProfiles)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
          <CompanyAvatar size="20px" domain={'instantly.ai'} mr={2} src={props.app?.logo} />
          Add to Instantly sequence
        </ModalHeader>
        <ModalBody pb={6}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={4}>
              <FormControl>
                <FormLabel>Campaign</FormLabel>
                {selectedCampaignId && <input type="hidden" name="campaign[campaign_id]" value={selectedCampaignId} />}

                {loadingCampaigns && <Spinner size="sm" />}
                {!loadingCampaigns && campaignData?.data?.campaigns && campaignData?.data?.campaigns.length < 1 && (
                  <Text p="4" bg="orange.50" m="2">
                    We couldn't find any Campaign to display. Make sure that the API key permissions in your Instantly
                    workspace are correct.
                  </Text>
                )}

                <ComboboxWithSearch
                  items={campaignData?.data?.campaigns ?? []}
                  selectedItem={campaignData?.data?.campaigns?.find((c) => c.id === selectedCampaignId) ?? null}
                  onChange={(selectedItem) => {
                    setSelectedCampaignId(selectedItem?.id ?? null)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val) || a.id.toLowerCase().includes(val)}
                  itemToString={(item) => (item ? `[${item.id}] ${item.name}` : '')}
                  itemRenderer={CampaignRenderer}
                  selectButtonRenderer={CampaignRenderer}
                  placeholder="Select a campaign"
                />
              </FormControl>
            </Stack>
            <Stack fontSize="sm" spacing={2} mt={4}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                SELECTED {pluralize(targetLabel, selectedProfiles?.length).toUpperCase()}:
              </Text>
              <Flex direction="column" alignItems="space-between" gap={3}>
                {profilesWithoutEmail && profilesWithoutEmail.length > 0 && !skipAnonymousProfiles && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                      You've selected {pluralize(targetLabel, profilesWithoutEmail.length, true)} whose emails have not
                      been enriched yet. They will be enriched if you proceed and will use enrichment credits in the
                      future.
                    </Text>
                    <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
                      Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open beta.
                    </Text>
                  </Stack>
                )}
                {profilesToAdd && (
                  <>
                    {profilesToAdd.length > 5 ? (
                      <>
                        {profilesToAdd?.slice(0, 4).map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.name}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                        {showMoreProfiles &&
                          profilesToAdd?.slice(4, profilesToAdd.length).map((selectedProfile) => (
                            <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                              <HStack>
                                <Avatar
                                  size="xs"
                                  name={selectedProfile.name}
                                  src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                                />
                                <Text>{selectedProfile.name}</Text>
                              </HStack>
                              <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                {selectedProfile.title}
                              </TextEllipsis>
                            </Flex>
                          ))}
                        <Text onClick={toggleShowMoreProfiles} color="purple.500" align="center" cursor="pointer">
                          {showMoreProfiles
                            ? `- Hide ${profilesToAdd.length - 4} ${targetLabel}`
                            : `+ Show ${profilesToAdd.length - 4} more ${targetLabel}`}
                        </Text>
                      </>
                    ) : (
                      <>
                        {profilesToAdd.map((selectedProfile) => (
                          <Flex key={selectedProfile.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProfile.name}
                                src={projectPath(`/prospects/${selectedProfile.id}/avatar`)}
                              />
                              <Text>{selectedProfile.name}</Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProfile.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Stack>
            {profilesWithoutEmail && profilesWithoutEmail.length > 0 && skipAnonymousProfiles && (
              <Stack spacing="4" p="2">
                <Text fontSize="xs" color="gray.500">
                  {pluralize(`anonymous ${targetLabel}`, profilesWithoutEmail.length, true)} selected won't be sequenced
                </Text>
              </Stack>
            )}
            <Flex w="100%" pt="4">
              <Button
                w="100%"
                size="sm"
                colorScheme={'purple'}
                type="submit"
                isLoading={saving || loadingCampaigns}
                isDisabled={
                  (campaignData?.data?.campaigns?.length ?? 0) < 1 || !selectedCampaignId || !profilesToAdd.length
                }
              >
                Add {targetPluralizedLabel} to Instantly Sequence
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface CampaignRendererProps {
  item: InstantlyCampaign | null
  isSelected?: boolean
  isToggleButton?: boolean
}

export function CampaignRenderer({ item }: CampaignRendererProps) {
  if (!item) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a Campaign
        </Text>
      </HStack>
    )
  }

  return (
    <HStack w="100%" key={item.id} px="1" flex="1" fontSize={'sm'}>
      <Stack spacing="-0.5">
        <Text fontSize="sm">{item.name}</Text>
        <Text fontSize={'xs'} color="gray.500">
          {item.id}
        </Text>
      </Stack>
    </HStack>
  )
}
