import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Link,
  Progress,
  SlideFade,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconSparkles } from '@tabler/icons-react'
import React, { useState, useEffect, useMemo } from 'react'
import { loadingPhrases } from './LoadingPhrases'
import { useRecommendation, useRecommendationStatus } from './AIRecommendationOutput'
import { Account } from '../../types/Account'
import { useCurrentUser } from './UserContext'
import AIRecommendationOutput from './AIRecommendationOutput'
import { IconRobotFace } from '@tabler/icons-react'
import { useProfileSearch } from '../data/use-profile-search'
import { useFacets } from '../data/use-facets'
import { Table, Tbody, Td, Th, Thead, Tr, Radio, RadioGroup } from '@chakra-ui/react'
import { LinkedinBoxIcon } from './icons'
import { ProfileRecord } from '../../types/Profile'

const ProfileList = ({ onSelect, account }: { onSelect: (person: any) => void; account: Account }) => {
  const facets = useFacets({
    range: 'any',
    facetCloudPath: '/profiles/facet-cloud',
    sortBy: 'last_seen'
  })

  const { data, isLoading: _isLoadingProfiles } = useProfileSearch({
    accountId: account.id,
    columns: ['email', 'name', 'title', 'company', 'linkedin_url'],
    queryString: facets.queryString
  })
  const topProfiles = useMemo(() => data?.profiles?.slice(0, 3), [data])

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th width="40px" padding={2}></Th>
          <Th>Email</Th>
          <Th>Name</Th>
          <Th width="100px">LinkedIn</Th>
        </Tr>
      </Thead>
      <Tbody>
        {topProfiles?.map((person) => (
          <Tr key={person.id} cursor="pointer" _hover={{ bg: 'gray.50' }}>
            <Td padding={2} onClick={() => onSelect(person)}>
              <Radio value={person.id} />
            </Td>
            <Td onClick={() => onSelect(person)}>
              <Text color="gray.700">{person.email}</Text>
            </Td>
            <Td onClick={() => onSelect(person)}>
              <Text fontWeight="medium">{person.name}</Text>
            </Td>
            <Td>
              {person.linkedin_url && (
                <Flex alignItems="center" gap={1}>
                  <Link
                    display="flex"
                    flex="none"
                    alignItems="center"
                    color="linkedin.700"
                    isExternal
                    href={person.linkedin_url}
                  >
                    <LinkedinBoxIcon boxSize="14px" />
                  </Link>
                </Flex>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

interface RecommendationSidebarProps {
  account: Account
  recommendation: any
}

export function RecommendationSidebar(props: RecommendationSidebarProps): JSX.Element | null {
  const [showRecommendation, setShowRecommendation] = useState(false)
  const [loadingPhrase, setLoadingPhrase] = useState(loadingPhrases[0])
  const [showRecommendationWhenReady, setShowRecommendationWhenReady] = useState(false)
  const currentUser = useCurrentUser()
  const [selectedPerson, setSelectedPerson] = useState<ProfileRecord>()

  const { data: response, isLoading, refetch } = useRecommendation(props.account.domain)
  const jobId = (response as any)?.job_id || props.recommendation?.job_id
  const recommendation = useRecommendationStatus(jobId, props.account.domain)
  const output = props.recommendation?.output || recommendation?.output
  if (output?.json_metadata && typeof output.json_metadata === 'string') {
    output.json_metadata = JSON.parse(output.json_metadata)
  }
  const loading = isLoading || (!output && jobId)

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingPhrase((prev) => {
          const currentIndex = loadingPhrases.indexOf(prev)
          return loadingPhrases[(currentIndex + 1) % loadingPhrases.length]
        })
      }, 5000)

      return () => clearInterval(interval)
    }

    if (!loading && showRecommendationWhenReady) {
      setShowRecommendationWhenReady(false)
      setShowRecommendation(true)
    }
  }, [loading, showRecommendationWhenReady])

  if (!currentUser.isInternalUser) {
    return null
  }

  if (output) {
    return (
      <>
        <Button
          flex="none"
          variant="outline"
          onClick={() => setShowRecommendation(true)}
          leftIcon={<IconRobotFace size={20} strokeWidth={1.5} />}
          justifyContent="flex-start"
          py={6}
          ps={5}
          fontWeight="semibold"
        >
          Show Recommendation
        </Button>

        <Drawer
          size="lg"
          placement="right"
          isOpen={showRecommendation}
          preserveScrollBarGap
          onClose={() => setShowRecommendation(false)}
        >
          <DrawerOverlay zIndex="modal" />

          <DrawerContent>
            <DrawerHeader>
              <Heading size="md" display="flex" gap={2} alignItems="center">
                Recommendation Output
              </Heading>
            </DrawerHeader>

            <DrawerCloseButton />

            <Box py="4">
              <DrawerBody paddingTop={0} paddingBottom={10}>
                <AIRecommendationOutput
                  key={selectedPerson?.id}
                  output={output}
                  targetEmail={selectedPerson?.email as string}
                  linkedin_url={selectedPerson?.linkedin_url as string}
                >
                  <Box bg="white" p={4} mb={4} rounded="lg" border="1px solid" borderColor="gray.200">
                    <Stack spacing={3}>
                      <Heading size="sm">Select Target Contact</Heading>
                      <RadioGroup value={selectedPerson?.id}>
                        <ProfileList onSelect={setSelectedPerson} account={props.account} />
                      </RadioGroup>
                    </Stack>
                  </Box>
                </AIRecommendationOutput>
              </DrawerBody>
            </Box>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  if (!output && !loading) {
    return (
      <Button
        flex="none"
        variant="outline"
        leftIcon={<IconSparkles size={16} />}
        isLoading={loading}
        onClick={() => {
          refetch()
          setShowRecommendationWhenReady(true)
        }}
        justifyContent="flex-start"
        py={6}
        ps={5}
        fontWeight="semibold"
      >
        Fetch Recommendation
      </Button>
    )
  }

  if (loading) {
    return (
      <>
        <Box height="20px">
          <SlideFade
            in={true}
            key={loadingPhrase} // Key prop triggers animation on phrase change
            transition={{ enter: { duration: 0.3, ease: 'linear' } }}
            offsetY="4px"
          >
            <Text fontSize="sm" textAlign="center" color="gray.500">
              {loadingPhrase}
            </Text>
          </SlideFade>
        </Box>
        <Progress height="3px" color="gray.200" isIndeterminate rounded="full" />
      </>
    )
  }

  return null
}
