import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { AccountView } from '../../../../types/AccountView'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { projectPath } from '../../../ui/ProjectsContext'
import { WarningMessage } from '../../../ui/WarningMessage'

function DeleteViewConfirmationModal({ view, onClose }: { view: AccountView; onClose: () => void }) {
  return (
    <DeleteConfirmation
      isOpen
      onClose={onClose}
      title="Delete list"
      // use ids for clarity, instead of slugs since the slug may be scoped to a space!
      deletePath={projectPath(`/views/${view.id}`)}
      confirmLabel="Yes, delete this list"
    >
      <Stack>
        {['shared', 'space'].includes(view.ownership || 'unknown') && !view.space?.owner_id && (
          <WarningMessage>
            This is a shared list. Deleting it will remove it for everyone using this list.
          </WarningMessage>
        )}
        <Text>
          Are you sure you want to delete{' '}
          <Text as="span" fontWeight="semibold">
            {view.name}
          </Text>
          ? This action can not be undone.
        </Text>
      </Stack>
    </DeleteConfirmation>
  )
}

export default DeleteViewConfirmationModal
