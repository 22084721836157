import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  OrderedList,
  Stack,
  Tag,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconCheck, IconPlayerPlay, IconPlus, IconTrash } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { toast } from 'sonner'
import { del, get, post } from '../../../lib/api'
import { Card } from '../../ui/Card'
import { CompanySelector, PartialCompany } from '../../ui/CompanySelector'
import { LoadingPhrases } from '../../ui/LoadingPhrases'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { titleize } from '../accounts/facets/filter-cloud'

interface Props {
  agents: Array<{
    name: string
    slug: string
    question: string
    category?: string
  }>
  my_agents: Array<{
    name: string
    slug: string
    question: string
  }>
}

export default function Index(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedAgent, setSelectedAgent] = React.useState<Props['agents'][0] | null>(null)
  const [selectedCompany, setSelectedCompany] = React.useState<PartialCompany | null>(null)
  const [isLoading, setIsLoading] = React.useState<Record<string, boolean>>({})
  const [myAgents, setMyAgents] = React.useState<Props['my_agents']>(props.my_agents)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)

  const myAgentsSlugs = useMemo(() => myAgents.map((agent) => agent.slug), [myAgents])

  const handleCardClick = useCallback(
    (agent: Props['agents'][0]) => {
      setSelectedAgent(agent)
      onOpen()
    },
    [onOpen]
  )

  const onAdd = useCallback(
    (agent: Props['agents'][0]) => {
      setIsLoading({
        ...isLoading,
        [agent.slug]: true
      })

      post<{ agents: Props['agents'] }>(projectPath('/settings/agents/add.json'), {
        ai_agent: {
          slug: agent.slug,
          name: agent.name,
          question: agent.question
        }
      })
        .then((res) => {
          toast.success('AI Agent was successfully added.')
          setMyAgents(res.agents)
          onClose()
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading({
            ...isLoading,
            [agent.slug]: false
          })
        })
    },
    [onClose, isLoading]
  )

  const handleDelete = useCallback(
    (agent: Props['agents'][0]) => {
      if (!confirm('Are you sure you want to remove this agent?')) return

      setIsLoading({
        ...isLoading,
        [agent.slug]: true
      })

      del<{ agents: Props['agents'] }>(projectPath(`/settings/agents/${agent.slug}`))
        .then((res) => {
          toast.success('AI Agent was successfully removed.')
          setMyAgents(res.agents)
        })
        .catch((err) => {
          toast.error(err.message)
        })
        .finally(() => {
          setIsLoading({
            ...isLoading,
            [agent.slug]: false
          })
        })
    },
    [isLoading]
  )

  const groupAgentsByCategory = (agents: Props['agents']) => {
    return agents.reduce(
      (acc, agent) => {
        const category = agent.category || 'My Agents'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(agent)
        return acc
      },
      {} as Record<string, Props['agents']>
    )
  }

  const groupedAgents = useMemo(() => groupAgentsByCategory(props.agents), [props.agents])
  const categories = useMemo(() => ['All', ...Object.keys(groupedAgents)], [groupedAgents])

  const filteredAgents = useMemo(() => {
    if (selectedCategory === 'My Agents') {
      return myAgents
    }
    if (!selectedCategory || selectedCategory === 'All') {
      return props.agents
    }
    return props.agents.filter((agent) => agent.category === selectedCategory)
  }, [props.agents, selectedCategory, myAgents])

  const getCategoryCount = useCallback(
    (category: string) => {
      if (category === 'All') {
        return props.agents.length
      }
      if (category === 'My Agents') {
        return myAgents.length
      }
      return props.agents.filter((agent) => agent.category === category).length
    },
    [props.agents, myAgents]
  )

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ title: 'AI Agents', path: projectPath('/settings/agents') }]} />

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <PageTitle>AI Agents</PageTitle>
          <Button
            as={Link}
            href={projectPath('/settings/agents/new')}
            iconSpacing={1}
            leftIcon={<IconPlus size={16} />}
            marginLeft="auto"
            alignSelf="center"
            size="sm"
            flex="none"
            colorScheme="purple"
          >
            New agent
          </Button>
        </Flex>
        <PageDescription>
          Configure AI agents for your workspace to automatically perform account research and find key details to help
          your team.
        </PageDescription>
      </SettingsHeader>

      <Flex gap={8}>
        <VStack
          align="stretch"
          spacing={2}
          minW="200px"
          position="sticky"
          top="24px"
          alignSelf="flex-start"
          display={{ base: 'none', md: 'flex' }}
        >
          {categories.map((category) => (
            <Button
              key={category}
              variant="ghost"
              justifyContent="flex-start"
              py={2}
              px={4}
              fontSize="sm"
              fontWeight="medium"
              color="gray.700"
              backgroundColor={selectedCategory === category ? 'gray.100' : 'transparent'}
              _hover={{ bg: 'gray.50' }}
              onClick={() => setSelectedCategory(category === 'All' ? null : category)}
              w="100%"
              minW="230px"
            >
              <HStack justify="space-between" width="100%" gap="4">
                <Text fontWeight={selectedCategory === category ? 'semibold' : 'normal'}>{titleize(category)}</Text>
                <Tag
                  size="sm"
                  fontSize="xs"
                  colorScheme={category === 'My Agents' ? 'purple' : 'gray'}
                  fontWeight={selectedCategory === category ? 'semibold' : 'normal'}
                >
                  {getCategoryCount(category)}
                </Tag>
              </HStack>
            </Button>
          ))}
        </VStack>

        <Stack spacing={8} flex={1}>
          {selectedCategory === 'My Agents' ? (
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="medium">
                My Agents
              </Text>
              <Button
                iconSpacing={1}
                leftIcon={<IconPlus size={16} />}
                size="sm"
                colorScheme="purple"
                as={Link}
                variant="outline"
                href={projectPath('/settings/agents/new')}
              >
                Add Agent
              </Button>
            </Flex>
          ) : null}
          <Grid
            gridAutoRows="1fr"
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 300px))"
            alignItems="stretch"
            gap={3}
            justifyContent="start"
          >
            {filteredAgents.map((agent) => (
              <Card
                position="relative"
                key={agent.slug}
                textAlign="center"
                p={6}
                gap={1}
                cursor="pointer"
                _hover={{ shadow: 'md', borderColor: 'purple.500' }}
                style={{ aspectRatio: '16 / 9' }}
                display="flex"
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
                maxW="300px"
                w="100%"
              >
                {myAgentsSlugs.includes(agent.slug) && (
                  <Box position="absolute" top="4" left="4">
                    <Checkbox isChecked />
                  </Box>
                )}
                <Stack spacing="1" pb="6">
                  <Text fontSize="sm" fontWeight="medium">
                    {agent.question}
                  </Text>
                </Stack>
                <Flex justifyContent="flex-end" w="100%" position="absolute" bottom="4" right="4" gap="1">
                  <Tooltip label="Test Agent">
                    <IconButton
                      size="xs"
                      aria-label="Test"
                      variant={'outline'}
                      onClick={() => handleCardClick(agent)}
                      icon={<IconPlayerPlay size="12" />}
                    />
                  </Tooltip>
                  {myAgentsSlugs.includes(agent.slug) ? (
                    <Tooltip label="Remove agent">
                      <IconButton
                        size="xs"
                        colorScheme="gray"
                        variant="outline"
                        icon={<IconTrash size={12} />}
                        aria-label="Remove"
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleDelete(agent)
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip label="Add agent">
                      <IconButton
                        size="xs"
                        aria-label="Add Agent"
                        isDisabled={isLoading[agent.slug] || myAgentsSlugs.includes(agent.slug)}
                        variant={'outline'}
                        isLoading={isLoading[agent.slug]}
                        icon={myAgentsSlugs.includes(agent.slug) ? <IconCheck size={12} /> : <IconPlus size={12} />}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          onAdd(agent)
                        }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Card>
            ))}
          </Grid>
        </Stack>
      </Flex>

      <AgentModal
        isOpen={isOpen}
        onClose={onClose}
        onAdd={() => {
          if (!selectedAgent) return
          onAdd(selectedAgent)
        }}
        selectedAgent={selectedAgent}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
    </PageLayout>
  )
}

interface AgentModalProps {
  isOpen: boolean
  onClose: () => void
  selectedAgent: {
    id?: string
    name: string
    slug: string
    question: string
  } | null
  selectedCompany: PartialCompany | null
  setSelectedCompany: (company: PartialCompany | null) => void
  onAdd: () => void
}

export function AgentModal({
  isOpen,
  onClose,
  selectedAgent,
  selectedCompany,
  setSelectedCompany,
  onAdd
}: AgentModalProps) {
  const [loading, setLoading] = React.useState(false)
  const [response, setResponse] = React.useState<any>(null)

  useEffect(() => {
    setResponse(null)
  }, [selectedAgent, selectedCompany])

  const runAgent = useCallback(async () => {
    if (!selectedCompany?.domain) return

    setLoading(true)
    setResponse(null)

    const path = selectedAgent?.id
      ? projectPath(`/assistants/${selectedAgent?.slug}?domain=${selectedCompany?.domain}`)
      : projectPath(`/assistants/run-template/${selectedAgent?.slug}?domain=${selectedCompany?.domain}`)
    const response = await get(path)

    setResponse(response)
    setLoading(false)
  }, [selectedAgent, selectedCompany])

  return (
    <Modal size="2xl" isOpen={isOpen && selectedAgent !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={6}>
          <Stack spacing={8}>
            <Stack>
              <Box>
                <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                  Question
                </Text>
                <Text fontSize="md" color="gray.700" fontWeight="medium">
                  {selectedAgent?.question}
                </Text>
              </Box>
              <CompanySelector selectedCompany={selectedCompany} onChange={setSelectedCompany} />
            </Stack>
            {loading && <LoadingPhrases isLoading={loading} />}
            {response?.short_answer && (
              <>
                <Stack>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                    Short Answer
                  </Text>
                  <Text fontSize="15px" lineHeight={1.5}>
                    {response.short_answer}
                  </Text>
                </Stack>
                <Stack>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                    Long Answer
                  </Text>
                  <Box fontSize="15px" lineHeight={1.5}>
                    <ReactMarkdown
                      components={{
                        h1: ({ node: _node, ...props }) => <Heading size="sm" {...props} />,
                        h2: ({ node: _node, ...props }) => <Heading size="xs" {...props} />,
                        h3: ({ node: _node, ...props }) => <Text fontSize="sm" fontWeight="medium" {...props} />,
                        li: ({ node: _node, ...props }) => <ListItem my="2" {...props} />,
                        ul: ({ node: _node, ...props }) => (
                          <UnorderedList marginLeft={0} paddingLeft="1.5rem" {...props} />
                        ),
                        ol: ({ node: _node, ...props }) => (
                          <OrderedList marginLeft={0} paddingLeft="1.5rem" {...props} />
                        )
                      }}
                    >
                      {response.detailed_answer}
                    </ReactMarkdown>
                  </Box>
                </Stack>
                {(response.sources || []).length > 0 && (
                  <Stack>
                    <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                      Sources
                    </Text>
                    <Grid gridAutoRows="1fr" gridTemplateColumns="repeat(3, minmax(0, 1fr))" gap={2}>
                      {response.sources.map((source) => {
                        const hostname = new URL(source.url).hostname
                        return (
                          <Card
                            key={source.url}
                            as={LinkBox}
                            display="flex"
                            flexDirection="column"
                            alignSelf="stretch"
                            justifyContent="space-between"
                            p={2}
                            borderRadius="md"
                            bg="gray.50"
                          >
                            <LinkOverlay href={source.url} isExternal>
                              <Text fontSize="xs" fontWeight="medium">
                                {source.title}
                              </Text>
                            </LinkOverlay>
                            <Flex alignItems="center" gap={1}>
                              <Image
                                width="16px"
                                height="16px"
                                src={`https://www.google.com/s2/favicons?domain=${hostname}&sz=${32}`}
                              />
                              <Text
                                flex="1 1 auto"
                                minW="100px"
                                fontSize="xs"
                                fontWeight="medium"
                                color="gray.500"
                                isTruncated
                              >
                                {hostname}
                              </Text>
                            </Flex>
                          </Card>
                        )
                      })}
                    </Grid>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              size="sm"
              colorScheme="purple"
              onClick={runAgent}
              variant="outline"
              isLoading={loading}
              isDisabled={!selectedCompany}
            >
              Test Agent
            </Button>
            <Button size="sm" colorScheme="purple" onClick={onAdd}>
              Add Agent
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
