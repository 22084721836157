import { Flex, Input, InputProps, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

interface TagsInputProps {
  initialTags?: string[]
  onChange: (tags: string[]) => void
  onRemove?: (tag: string) => void
  colorScheme?: string
  placeholder?: string
  inputProps?: InputProps
}

interface TagItemProps {
  tag: string
  onRemove: () => void
  colorScheme: string
}

const TagItem: React.FC<TagItemProps> = ({ tag, onRemove, colorScheme }) => {
  return (
    <Tag size="sm" variant="subtle" colorScheme={colorScheme} onClick={(e) => e.stopPropagation()}>
      <TagLabel>{tag}</TagLabel>
      <TagCloseButton
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onRemove()
        }}
        cursor="pointer"
      />
    </Tag>
  )
}

export const TagsInput: React.FC<TagsInputProps> = ({
  initialTags = [],
  onChange,
  onRemove,
  colorScheme = 'purple',
  placeholder,
  inputProps = {}
}) => {
  const [tags, setTags] = useState<string[]>(initialTags)
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const addTag = (tag: string) => {
    if (tag.trim() && !tags.includes(tag.trim())) {
      let incoming: string[] = []
      if (tag.includes(' OR ')) {
        incoming = tag.split(' OR ')
      } else {
        incoming = [tag.trim()]
      }

      const newTags = [...tags, ...incoming].map((t) => t.trim())
      setTags(newTags)
      onChange(newTags)
    }
  }

  const removeTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index)
    setTags(newTags)
    onRemove?.(tags[index])
    onChange(newTags)
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
      e.preventDefault()
      if (inputValue.trim()) {
        addTag(inputValue.trim())
        setInputValue('')
      }
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      if (!inputValue && tags.length > 0) {
        // Remove the last tag when backspace/delete is pressed and input is empty
        e.preventDefault()
        removeTag(tags.length - 1)
      }
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    const pastedTags = pastedText
      .split(/[,\n(OR)]/)
      .map((tag) => tag.trim())
      .filter(Boolean)
    const newTags = [...tags, ...pastedTags.filter((tag) => !tags.includes(tag))]
    setTags(newTags)
    onChange(newTags)
    setInputValue('')
  }

  return (
    <Flex
      flexWrap="wrap"
      gap={1}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      p={1.5}
      w="100%"
      onClick={() => inputRef.current?.focus()}
    >
      {tags.map((tag, index) => (
        <TagItem key={tag} tag={tag} onRemove={() => removeTag(index)} colorScheme={colorScheme} />
      ))}
      <Input
        ref={inputRef}
        size="sm"
        flex="1 1 0%"
        width="auto"
        minW="min(100px, 100%)"
        variant="unstyled"
        px={1.5}
        fontSize="13px"
        fontWeight="medium"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder ?? 'Add a tag'}
        onBlur={() => {
          if (inputValue.trim()) {
            addTag(inputValue.trim())
            setInputValue('')
          }
        }}
        {...inputProps}
      />
    </Flex>
  )
}

export default TagsInput
