import AIRecommendationOutput, {
  useRecommendation,
  useRecommendationStatus
} from '@app/components/ui/AIRecommendationOutput'
import { UserAppInstance } from '@app/types/UserAppInstance'
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import {
  IconAdjustmentsHorizontal,
  IconAlarm,
  IconArrowRight,
  IconBan,
  IconBriefcase,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconFlame,
  IconSparkles,
  IconUsers,
  IconWorldPin
} from '@tabler/icons-react'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { toast } from 'sonner'
import { patch } from '../../../lib/api'
import { greeting } from '../../../lib/dayjs'
import { formatFriendlyCurrency } from '../../../lib/number-format'
import router from '../../../lib/router'
import { Company } from '../../../types/Profile'
import Avatar from '../../ui/Avatar'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { CompanyBubble } from '../../ui/CompanyBubble'
import { Iconify } from '../../ui/Iconify'
import { LinkedinBoxIcon, SfOpportunityIcon } from '../../ui/icons'
import { LoadingPhrases } from '../../ui/LoadingPhrases'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import { SmallPageHeading } from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser, User } from '../../ui/UserContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import NoPixel from '../accounts/components/empty-states/no-pixel.svg'
import { accountPath } from '../accounts/lib/account-path'
import { signalTypes } from '../kql_definitions/components/triggers'
import { UserSelect } from '../mission_control/UserSelect'
import { profilePath } from '../profiles/lib/path'

const emptyArray = []

function getSignalColorScheme(signalType: string) {
  return signalTypes.find((s) => s.kind === signalType)?.colorScheme
}

function groupItemsByCompany(items: any[]): Record<string, any[]> {
  return items.reduce(
    (acc, item) => {
      const companyId = item.target.company?.id || 'no company'
      if (!acc[companyId]) {
        acc[companyId] = []
      }
      acc[companyId].push(item)
      return acc
    },
    {} as Record<string, any[]>
  )
}

interface InboxItem {
  id: string
  user_id?: string
  account_id?: string
  target_id: string
  target_type: 'Profile' | 'ProspectedProfile'
  status: 'dismissed' | 'pending' | 'completed' | 'snoozed'
  context: {
    territories?: Array<any>
    routing?: 'ownership' | 'round_robin' | 'account_affinity'
    personas?: Array<{
      id: string
      name: string
    }>
  }
  signals: Array<{
    id: string
    name: string
    signal_type: string
    last_triggered_at: string
  }>
  created_at?: string
  updated_at?: string
  last_read_at?: string
  snoozed_until?: string
  notified_at?: string
  dismissed_at?: string
  dismissed_context?: {
    reason: string
    feedback?: string
  }
  acted_by?: string
}

interface Props {
  inbox_items: InboxItem[]
  selected_user?: User
  apps?: UserAppInstance[]
}

export default function Inbox(props: Props) {
  const currentUser = useCurrentUser()
  const [selectedUser, setSelectedUser] = useState<Partial<User> | null>(props.selected_user || null)
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })
  const [inboxItems, setInboxItems] = useState(props.inbox_items || [])
  const [selectedItem, setSelectedItem] = useState(inboxItems[0] || null)

  const isUnassigned = useMemo(() => window.location.pathname.endsWith('/unassigned'), [])
  const isPreview = useMemo(() => window.location.search.includes('preview=1'), [])

  const grouped = useMemo(() => groupItemsByCompany(inboxItems), [inboxItems])
  const itemsInGroupOrder = useMemo(() => Object.values(grouped).flat(), [grouped])

  const handleSelectItem = useCallback(
    (item: any) => {
      setSelectedItem(item)

      if (!isPreview && item?.id && item.user_id === currentUser?.id) {
        patch(projectPath(`/inbox_items/${item.id}/update_last_read`), {})
      }
    },
    [setSelectedItem, currentUser, isPreview]
  )

  useEffect(() => {
    setInboxItems(inboxItems || [])
  }, [inboxItems])

  useUpdateEffect(() => {
    const user = selectedUser && selectedUser.id !== currentUser.id ? selectedUser : undefined
    const email = user?.email

    if (email !== props.selected_user?.email) {
      const path = projectPath(`/inbox/${email || ''}`)
      router.visit(path)
    }
  }, [selectedUser, currentUser.id, props.selected_user?.email])

  const onPrevious = useCallback(() => {
    const index = itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id)
    const previous = itemsInGroupOrder[index - 1]
    if (previous) {
      handleSelectItem(previous)
    }
  }, [itemsInGroupOrder, selectedItem, handleSelectItem])

  const onNext = useCallback(() => {
    const index = itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id)
    const next = itemsInGroupOrder[index + 1]
    if (next) {
      handleSelectItem(next)
    }
  }, [itemsInGroupOrder, selectedItem, handleSelectItem])

  const hasPrevious = useMemo(() => {
    return itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id) > 0
  }, [itemsInGroupOrder, selectedItem])

  const hasNext = useMemo(() => {
    return itemsInGroupOrder.findIndex((item) => item.id === selectedItem?.id) < itemsInGroupOrder.length - 1
  }, [itemsInGroupOrder, selectedItem])

  const itemRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    if (selectedItem) {
      const itemRef = itemRefs.current[selectedItem.id] as undefined | HTMLDivElement
      if (itemRef) {
        if ((itemRef as any).scrollIntoViewIfNeeded) {
          // @ts-expect-error untyped
          itemRef.scrollIntoViewIfNeeded(false)
        } else {
          itemRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }
  }, [selectedItem])

  const actOnItem = useCallback(
    (item: any, direction: 'left' | 'right' | 'up') => {
      const actedId = item?.id
      const index = itemsInGroupOrder.findIndex((item) => item.id === actedId)

      // Determine the next selected item
      const nextItem = itemsInGroupOrder[index + 1] || itemsInGroupOrder[index - 1] || null

      const itemRef = itemRefs.current[actedId]
      if (itemRef) {
        const transformDirection = {
          left: 'translateX(-100%)',
          right: 'translateX(+100%)',
          up: 'translateY(-100%)'
        }
        // Apply animation styles
        itemRef.style.transition = 'background-color 0.3s ease, transform 0.5s ease, opacity 0.5s ease'
        itemRef.style.backgroundColor = '#e2e8f0'
        itemRef.style.transform = transformDirection[direction]
        itemRef.style.opacity = '0'
      }

      // Update the inbox items
      setTimeout(() => {
        setInboxItems((prev) => prev.filter((item) => item.id !== actedId))
        setTimeout(() => handleSelectItem(nextItem), 0)
      }, 500)
    },
    [itemsInGroupOrder, handleSelectItem]
  )

  const onDismiss = useCallback(
    (item: any) => {
      actOnItem(item, 'left')
    },
    [actOnItem]
  )

  const onSnooze = useCallback(
    (item: any) => {
      actOnItem(item, 'up')
    },
    [actOnItem]
  )

  const onComplete = useCallback(
    (item: any) => {
      actOnItem(item, 'right')
    },
    [actOnItem]
  )

  // Add keyboard navigation
  useHotkeys('up,left,k', onPrevious, { filterPreventDefault: true }, [onPrevious])
  useHotkeys('down,right,j', onNext, { filterPreventDefault: true }, [onNext])

  const hasGmail = props.apps?.some((app) => app.app_module === 'UserApps::Gmail::App' && app.connected) || false

  return (
    <PageLayout size="full" flush gap={0} bg="gray.50" maxH="100%" minH="300px">
      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
          {canViewAsMember ? (
            <Tabs size="sm" variant="subtle" isManual defaultIndex={isUnassigned ? 2 : 1}>
              <TabList>
                <Tab as="a" href={projectPath('/inbox/dashboard')}>
                  Dashboard
                </Tab>
                <Tab as="a" href={projectPath('/inbox')}>
                  Inbox
                </Tab>
                <Tab as="a" href={projectPath('/inbox/unassigned')}>
                  Unassigned
                </Tab>
              </TabList>
            </Tabs>
          ) : (
            <SmallPageHeading>{isUnassigned ? 'Unassigned Inbox' : 'Inbox'}</SmallPageHeading>
          )}

          {canViewAsMember && (
            <Flex flex="none" alignItems="center" gap={2}>
              <Flex alignItems="center" gap={1}>
                <Text fontSize="sm" color="gray.600">
                  Viewing as
                </Text>
                <UserSelect
                  selectedUserId={selectedUser?.id || currentUser!.id}
                  onChange={(_userId, user) => setSelectedUser(user as Partial<User>)}
                  isReadOnly={!canViewAsMember}
                />
              </Flex>

              <IconButton
                aria-label="Settings"
                icon={<IconAdjustmentsHorizontal size={16} />}
                variant="outline"
                size="sm"
                as={Link}
                href={projectPath('/settings/ai-instructions')}
              />
            </Flex>
          )}
        </Flex>
      </TopBarContent>

      <Flex height="100%">
        <Stack padding={6} spacing={8} flex="1" overflow="auto">
          <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="semibold">
                {greeting()}, {props.selected_user?.first_name || currentUser.firstName} 👋
              </Text>
              {inboxItems.length > 0 ? (
                <Text fontSize="sm" color="gray.500">
                  We've found {inboxItems.length.toLocaleString()}{' '}
                  {pluralize(isUnassigned ? 'unassigned lead' : 'lead', inboxItems.length)} for you to review
                </Text>
              ) : (
                <Text fontSize="sm" color="gray.500">
                  All caught up! Check back later
                </Text>
              )}
            </Stack>
          </Flex>
          <Stack spacing={1}>
            {Object.entries(grouped).map(([companyId, items]) => {
              if (items.length > 1) {
                return (
                  <ExpandableCompanyCard
                    key={companyId}
                    items={items}
                    selectedItem={selectedItem}
                    onSelect={(item) => handleSelectItem(item)}
                  />
                )
              }
              const item = items[0]

              return (
                <Box
                  key={items[0].id}
                  ref={(el) => (itemRefs.current[item.id] = el)}
                  bg="white"
                  rounded="lg"
                  shadow="sm"
                  cursor="pointer"
                  transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                  border="2px solid transparent"
                  borderColor={selectedItem?.id === item.id ? 'purple.500' : 'transparent'}
                  _hover={{ transform: 'scale(101%)', shadow: 'base' }}
                  onClick={() => handleSelectItem(item)}
                >
                  <Flex px={3} py={2} alignItems="flex-start" gap={4}>
                    <Flex flex="1" alignItems="center" gap={2}>
                      <Box flex="none" position="relative" p={1.5}>
                        <CompanyAvatar domain={item.target.company?.domain} size="36px" />
                        <Avatar
                          src={
                            item.target.image || item.target.koala_prospect?.id
                              ? projectPath(`/prospects/${item.target.koala_prospect.id}/avatar`)
                              : undefined
                          }
                          name={item.target.name || item.target.full_name || item.target.display_name}
                          size="xs"
                          position="absolute"
                          right={0}
                          bottom={0}
                          border="1.5px solid white"
                        />
                      </Box>
                      <Stack spacing={0}>
                        <Flex alignItems="center" gap={2}>
                          <Text fontSize="sm" fontWeight="medium">
                            {item.target.name || item.target.full_name || item.target.display_name || item.target.email}
                          </Text>

                          {item.target_type === 'Profile' && <WarmLeadBadge />}
                          {item.target_type === 'ProspectedProfile' && <ProspectBadge />}
                        </Flex>
                        <Text fontSize="13px" color="gray.500">
                          {[item.target.title, item.target.company?.name].filter(Boolean).join(' @ ')}
                        </Text>
                      </Stack>
                    </Flex>

                    {item.signals && item.signals.length > 0 ? (
                      <Flex flex="none" alignItems="center" gap={2} fontSize="13px">
                        <Badge
                          variant="regular"
                          rounded="full"
                          px={2}
                          py={0.5}
                          colorScheme={getSignalColorScheme(item.signals[0].signal_type)}
                        >
                          {item.signals[0].name}
                        </Badge>
                        <Text color="gray.400">
                          <TimeAgo time={item.signals[0].last_triggered_at} canToggle={false} />
                        </Text>
                      </Flex>
                    ) : (
                      <Text flex="none" fontSize="13px" color="gray.400">
                        <TimeAgo time={item.created_at} canToggle={false} />
                      </Text>
                    )}
                  </Flex>
                </Box>
              )
            })}

            {inboxItems.length === 0 && <InboxEmptyState />}
          </Stack>
        </Stack>

        {selectedItem && (
          <Flex alignItems="stretch" justifyContent="stretch" maxW="1080px" width="50%" minW="340px" p={2}>
            <SelectedItemPanel
              key={selectedItem.id}
              item={selectedItem}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              onNext={onNext}
              onPrev={onPrevious}
              onDismiss={onDismiss}
              onSnooze={onSnooze}
              hasGmail={hasGmail}
              onComplete={onComplete}
              isPreview={isPreview}
            />
          </Flex>
        )}
      </Flex>
    </PageLayout>
  )
}

interface ExpandableCompanyCardProps {
  items: any[]
  selectedItem?: any
  onSelect?: (item: any) => void
}

function ExpandableCompanyCard({ items, selectedItem, onSelect }: ExpandableCompanyCardProps) {
  const company: Company | undefined = items[0].target.company
  const firstSignal = items.flatMap((item) => item.signals).sort((a, b) => a.last_triggered_at - b.last_triggered_at)[0]

  return (
    <Box
      bg="white"
      rounded="lg"
      shadow="sm"
      cursor="pointer"
      transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      border="2px solid transparent"
      borderColor={selectedItem?.target.company?.id === company?.id ? 'purple.500' : 'transparent'}
      _hover={{ transform: 'scale(101%)', shadow: 'base' }}
      onClick={selectedItem?.target.company?.id === company?.id ? undefined : () => onSelect?.(items[0])}
    >
      <Stack spacing={2.5} px={3} py={2}>
        <Flex alignItems="flex-start" gap={4}>
          <Flex flex="1" alignItems="center" gap={2}>
            <Box flex="none" position="relative" p={1.5}>
              <CompanyAvatar domain={company?.domain} size="36px" />
            </Box>
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="medium">
                {company?.name || company?.domain}
              </Text>
              <Text fontSize="13px" color="gray.500">
                {items.map((item) => item.target.name || item.target.full_name || item.target.display_name).join(', ')}
              </Text>
            </Stack>
          </Flex>

          {firstSignal && (
            <Flex flex="none" alignItems="center" gap={2} fontSize="13px">
              <Text color="gray.400">
                <TimeAgo time={firstSignal.last_triggered_at} canToggle={false} />
              </Text>
            </Flex>
          )}
        </Flex>

        <Stack spacing={0} border="1px solid" borderColor="gray.200" rounded="md" divider={<Divider />}>
          {items.map((item) => (
            <Flex
              key={item.target.id}
              px={2}
              py={2}
              alignItems="center"
              gap={2.5}
              onClick={(e) => {
                // select this specific item and not the parent
                e.stopPropagation()
                onSelect?.(item)
              }}
              margin={1}
              bg={selectedItem?.id === item.id ? 'background.light' : 'white'}
              borderRadius="md"
              zIndex={selectedItem?.id === item.id ? 1 : 0}
            >
              <Avatar
                size="36px"
                src={
                  item.target.image || item.target.koala_prospect?.id
                    ? projectPath(`/prospects/${item.target.koala_prospect.id}/avatar`)
                    : undefined
                }
                name={item.target.name || item.target.full_name || item.target.display_name}
              />
              <Stack spacing={0}>
                <Flex alignItems="center" gap={2}>
                  <Text fontSize="sm" fontWeight="medium">
                    {item.target.name || item.target.full_name || item.target.display_name || item.target.email}
                  </Text>

                  {/* TODO only show this if this was the signal *actor* */}
                  {item.target_type === 'Profile' && <WarmLeadBadge />}
                  {item.target_type === 'ProspectedProfile' && <ProspectBadge />}

                  {item.signals && item.signals.length > 0 && (
                    <Badge
                      variant="regular"
                      rounded="full"
                      px={1.5}
                      py={0.5}
                      colorScheme={getSignalColorScheme(item.signals[0].signal_type)}
                    >
                      {item.signals[0].name}
                    </Badge>
                  )}
                </Flex>
                <Text fontSize="13px" color="gray.500">
                  {item.target.title}
                </Text>
              </Stack>
            </Flex>
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

interface SelectedItemPanelProps {
  item: any
  hasNext?: boolean
  hasPrevious?: boolean
  hasGmail?: boolean
  onNext?: () => void
  onPrev?: () => void
  onDismiss: (item: any) => void
  onSnooze: (item: any) => void
  onComplete: (item: any) => void
  isPreview?: boolean
}

function SelectedItemPanel({
  hasGmail,
  item,
  hasNext,
  hasPrevious,
  onNext,
  onPrev,
  onDismiss,
  onSnooze,
  onComplete,
  isPreview
}: SelectedItemPanelProps) {
  const company = item.target.company
  const opportunities = item.target.salesforce_opportunities || emptyArray
  const targetEmail = item.target.email

  const domain = company?.domain
  const geo =
    company.location || uniq(compact([company.geo?.city, company.geo?.stateCode, company.geo?.country])).join(', ')
  const employees = company.metrics?.employeesRange || company.metrics?.employees

  const hasOpportunities = opportunities.length > 0

  const [openOpportunities, closedOpportunities] = useMemo(() => {
    return opportunities.reduce(
      ([open, closed], opp) => {
        if (opp.payload.IsClosed) {
          return [open, [...closed, opp]]
        }
        return [[...open, opp], closed]
      },
      [[], []] as [typeof opportunities, typeof opportunities]
    )
  }, [opportunities])

  const mostRecentClosedOpportunity = useMemo(() => {
    return closedOpportunities.reduce((latest, opp) => {
      null
      const oppDate = new Date(opp.payload.LastModifiedDate)
      const latestDate = new Date(latest.payload.LastModifiedDate)
      return oppDate > latestDate ? opp : latest
    }, closedOpportunities[0])
  }, [closedOpportunities])

  const opportunitiesToShow = useMemo(() => {
    if (openOpportunities.length > 0) {
      return [...openOpportunities, mostRecentClosedOpportunity].filter(Boolean)
    }
    return mostRecentClosedOpportunity ? [mostRecentClosedOpportunity] : []
  }, [openOpportunities, mostRecentClosedOpportunity])

  const { data: response, isLoading, refetch } = useRecommendation(domain)
  const job_id = (response as any)?.job_id || item.recommendation?.job_id
  const recommendation = useRecommendationStatus(job_id, domain)
  const output = item.recommendation?.output || recommendation?.output

  if (output?.json_metadata && typeof output.json_metadata === 'string') {
    output.json_metadata = JSON.parse(output.json_metadata)
  }

  const loading = isLoading || (!output && job_id)

  const handleComplete = () => {
    if (isPreview) return
    patch(projectPath(`/inbox_items/${item.id}/complete`), {})
      .then(() => {
        onComplete(item)
      })
      .catch((error) => {
        toast.error(`There was an issue completing this lead`, { description: error?.message })
      })
  }

  useHotkeys('c', handleComplete, { filterPreventDefault: true, enabled: !isPreview }, [handleComplete])

  return (
    <Flex
      flexDirection="column"
      flex="1"
      maxW="100%"
      bg="white"
      shadow="lg"
      rounded="lg"
      border="1px solid"
      borderColor="gray.200"
    >
      <Flex
        borderBottom="1px solid"
        borderColor="gray.200"
        flex="none"
        justifyContent="space-between"
        gap={4}
        px={4}
        py={4}
      >
        <Flex flex="1" alignItems="center" gap={2}>
          <Box display="flex" flex="none" position="relative">
            <Avatar
              src={
                item.target.image || item.target.koala_prospect?.id
                  ? projectPath(`/prospects/${item.target.koala_prospect.id}/avatar`)
                  : undefined
              }
              name={item.target.name || item.target.full_name || item.target.display_name}
              size="40px"
            />
            <Box position="absolute" transform="translate(18%, 18%)" right={0} bottom={0}>
              <CompanyAvatar
                domain={item.target.company?.domain}
                size="18px"
                rounded="full"
                boxShadow="0 0 0 2px white"
                background="white"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Stack spacing={0}>
            <Flex alignItems="center" gap={1.5}>
              <Text
                as={!item.target.email ? Text : Link}
                href={profilePath(item.target)}
                isExternal
                fontSize="sm"
                fontWeight="semibold"
              >
                {item.target.name || item.target.full_name || item.target.display_name || item.target.email}
              </Text>
              {item.target.linkedin_url && (
                <Tooltip label={`https://${item.target.linkedin_url.replace(/https?:\/\//, '')}`}>
                  <Link
                    display="flex"
                    flex="none"
                    alignItems="center"
                    color="linkedin.700"
                    isExternal
                    href={`https://${item.target.linkedin_url.replace(/https?:\/\//, '')}`}
                  >
                    <LinkedinBoxIcon boxSize="18px" />
                  </Link>
                </Tooltip>
              )}
              {/* TODO only show this if this was the signal *actor* */}
              {item.target_type === 'Profile' && <WarmLeadBadge />}
              {item.target_type === 'ProspectedProfile' && <ProspectBadge />}
            </Flex>
            <Text fontSize="13px" color="gray.500">
              {[item.target.title, item.target.company?.name].filter(Boolean).join(' @ ')}
            </Text>
          </Stack>
        </Flex>

        <Flex flex="none" gap={2}>
          {item.target_type == 'Profile' && (
            <Button
              as={Link}
              href={profilePath(item.target)}
              isExternal
              variant="outline"
              size="sm"
              rightIcon={<IconArrowRight size={15} />}
              iconSpacing={1.5}
            >
              View profile
            </Button>
          )}

          <Flex gap={0}>
            <IconButton
              aria-label="Previous"
              icon={<IconChevronLeft size={15} />}
              variant="ghost"
              size="sm"
              onClick={onPrev}
              isDisabled={!hasPrevious}
            />
            <IconButton
              aria-label="Next"
              icon={<IconChevronRight size={15} />}
              variant="ghost"
              size="sm"
              onClick={onNext}
              isDisabled={!hasNext}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex flex="1" flexDirection="column" gap={4} px={4} py={5} overflowX="hidden" overflowY="auto">
        <Box bg="background.light" border="1px solid" borderColor="gray.200" p={4} pl={5} rounded="lg">
          <Stack spacing={3}>
            <Flex width="100%" justify="space-between" alignItems="center">
              <CompanyBubble domain={company?.domain} name={company?.name} href={accountPath({ company })} />

              {!hasOpportunities && (
                <Badge
                  variant="regular"
                  colorScheme="green"
                  border="1px solid"
                  borderColor="green.200"
                  rounded="full"
                  px={1.5}
                  py={0.5}
                >
                  New opportunity
                </Badge>
              )}
              {/* <HStack spacing={2}>
                {company?.linkedin_url && (
                  <Tooltip label={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}>
                    <Button
                      as={Link}
                      href={`https://${company.linkedin_url.replace(/https?:\/\//, '')}`}
                      isExternal
                      aria-label="LinkedIn"
                      bg="white"
                      leftIcon={<LinkedinBoxIcon boxSize={4} />}
                      iconSpacing={1.5}
                      size="sm"
                      fontSize="13px"
                      variant="outline"
                    >
                      LinkedIn
                    </Button>
                  </Tooltip>
                )}
              </HStack> */}
            </Flex>

            {company?.description && (
              <Text fontSize="13px" color="gray.600" noOfLines={3}>
                {company.description}
              </Text>
            )}

            <Flex columnGap={5} rowGap={2} color="gray.600" flexWrap="wrap">
              {geo && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconWorldPin size={16} />
                  <Text>{geo}</Text>
                </HStack>
              )}
              {company?.category?.industry && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconBriefcase size={16} />
                  <Text>{company.category.industry}</Text>
                </HStack>
              )}
              {employees && (
                <HStack spacing={1.5} fontWeight="medium" fontSize="xs">
                  <IconUsers size={16} />
                  <Text>{employees} employees</Text>
                </HStack>
              )}
            </Flex>

            {hasOpportunities && (
              <>
                <Divider />

                {opportunitiesToShow.map((opportunity) => (
                  <Flex key={opportunity.payload.Id} alignItems="center" gap={2.5} py={1}>
                    <Center height="20px">
                      <Iconify icon={SfOpportunityIcon} size={18} />
                    </Center>

                    <HStack flex="1" fontSize="13px" spacing={1} divider={<MiddotDivider />}>
                      <Text fontWeight="medium" color="gray.700" lineHeight="20px">
                        {opportunity.payload.Name}: ({opportunity.payload.StageName})
                      </Text>

                      {opportunity.payload.Amount && (
                        <Text color="gray.500">{formatFriendlyCurrency(opportunity.payload.Amount)}</Text>
                      )}
                      {opportunity.payload.Probability && !opportunity.payload.IsClosed && (
                        <Text color="gray.500">{`${opportunity.payload.Probability}% probability`}</Text>
                      )}
                    </HStack>

                    {/* show the time ago */}
                    <Text fontSize="13px" color="gray.500">
                      <TimeAgo time={opportunity.payload.LastModifiedDate} canToggle={false} />
                    </Text>
                  </Flex>
                ))}
              </>
            )}
          </Stack>
        </Box>

        {!output && !loading && (
          <Button
            flex="none"
            variant="outline"
            leftIcon={<IconSparkles size={16} />}
            isLoading={loading}
            onClick={() => refetch()}
          >
            Fetch Recommendation
          </Button>
        )}

        {loading && <LoadingPhrases isLoading={loading} />}

        <AIRecommendationOutput
          output={output}
          hasGmail={hasGmail}
          targetEmail={targetEmail!}
          linkedin_url={item.target.linkedin_url}
          onEmailSent={handleComplete}
        />
      </Flex>

      <Flex flex="none" alignItems="center" gap={4} px={4} pt={3} pb={4} justifyContent="space-between">
        <Flex alignItems="center" gap={0}>
          <DismissMenu item={item} onDismiss={onDismiss} isDisabled={isPreview} />
        </Flex>

        <Flex alignItems="center" gap={2}>
          <SnoozeMenu item={item} onSnooze={onSnooze} isDisabled={isPreview} />
          <Button variant="solid" size="md" colorScheme="purple" onClick={handleComplete} isDisabled={isPreview}>
            Mark as completed
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

function InboxEmptyState() {
  return (
    <Stack textAlign="center" bg="white" p="12" maxW="600" mx="auto" spacing="4" borderWidth="1px" rounded="xl">
      <Center>
        <Image src={NoPixel} maxW="600" />
      </Center>
      <Heading size="sm" fontWeight="semibold">
        No new leads yet
      </Heading>
      <Text color="gray.500" fontSize="sm">
        Check back later, Koala will automatically spot key signals and surface leads from all of your connected
        sources.
      </Text>
    </Stack>
  )
}

const dismissReasons: [string, string][] = [
  ['Wrong territory', 'not_my_territory'],
  ['Not ICP', 'not_in_icp'],
  ['Bad lead', 'bad_lead'],
  ['Bad signal', 'bad_signal']
]

interface DismissMenuProps {
  item: any
  onDismiss: (item: any) => void
  isDisabled?: boolean
}

function DismissMenu(props: DismissMenuProps) {
  const [feedback, setFeedback] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)

  const handleDismiss = (reason: string) => {
    patch(projectPath(`/inbox_items/${props.item?.id}/dismiss`), {
      reason: reason,
      feedback: reason === 'other' ? feedback : ''
    })
      .then(() => {
        setFeedback('')
        setIsModalOpen(false)
        props.onDismiss(props.item)
      })
      .catch((error) => {
        toast.error(`There was an issue dismissing this lead`, { description: error?.message })
      })
  }

  useHotkeys(
    'd',
    () => {
      menuButtonRef.current?.click()
    },
    { filterPreventDefault: true, enabled: !props.isDisabled },
    [menuButtonRef]
  )

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              aria-label="Bad result"
              size="md"
              color="gray.500"
              _hover={{ color: 'gray.700', bg: 'gray.100' }}
              leftIcon={<IconBan size={16} />}
              rightIcon={isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              variant="ghost"
              ref={menuButtonRef}
              isDisabled={props.isDisabled}
            >
              Dismiss
            </MenuButton>
            <MenuList>
              {dismissReasons.map(([label, reason]) => (
                <MenuItem key={reason} onClick={() => handleDismiss(reason)}>
                  {label}
                </MenuItem>
              ))}
              <MenuDivider />
              <MenuItem onClick={() => setIsModalOpen(true)}>Other</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove lead from your inbox</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.700">
                Totally optional, we'd love to know why you're dismissing this lead.
              </Text>
              <Textarea
                placeholder="Share your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size="sm"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={() => handleDismiss('other')} ml={3}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface SnoozeMenuProps {
  item: any
  onSnooze: (item: any) => void
  isDisabled?: boolean
}

function SnoozeMenu(props: SnoozeMenuProps) {
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const snoozePeriods: [string, string][] = [
    ['in 2 minutes', 'in 2 minutes'],
    ['in 1 hour', 'in 1 hour'],
    ['tomorrow', 'tomorrow, 9am'],
    ['next week', 'next week, Mon 9am'],
    ['next month', 'next month, 9am']
  ]

  const handleSnooze = (until: string) => {
    patch(projectPath(`/inbox_items/${props.item?.id}/snooze`), {
      until: until
    })
      .then(() => {
        props.onSnooze(props.item)
      })
      .catch((error) => {
        toast.error(`There was an issue snoozing this lead`, { description: error?.message })
      })
  }

  useHotkeys(
    's',
    () => {
      menuButtonRef.current?.click()
    },
    { filterPreventDefault: true, enabled: !props.isDisabled },
    [menuButtonRef]
  )

  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              aria-label="Snooze"
              size="md"
              color="gray.500"
              _hover={{ color: 'gray.700', bg: 'gray.100' }}
              leftIcon={<IconAlarm size={16} />}
              rightIcon={isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
              variant="outline"
              ref={menuButtonRef}
              isDisabled={props.isDisabled}
            >
              Snooze
            </MenuButton>
            <MenuList>
              {snoozePeriods.map(([label, value]) => (
                <MenuItem key={label} onClick={() => handleSnooze(value)}>
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  )
}

function WarmLeadBadge() {
  return (
    <Badge
      display="inline-flex"
      alignItems="center"
      gap={0.5}
      lineHeight="18px"
      variant="subtleBorder"
      bg="orange.50"
      borderColor="orange.200"
      px={1.5}
    >
      <Icon as={IconFlame} boxSize={3.5} color="orange.500" ml={-1} />
      <Text>Warm</Text>
    </Badge>
  )
}

function ProspectBadge() {
  return (
    <Badge variant="subtleBorder" colorScheme="gray" borderColor="gray.200" px={1.5}>
      Prospect
    </Badge>
  )
}
