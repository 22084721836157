import { Box, Button, Flex, FlexProps, Grid, Heading, Link, Stack, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import { capitalize } from 'lodash'
import React, { useMemo } from 'react'
import { greeting } from '../../../lib/dayjs'
import { User } from '../../../types/Invite'
import Avatar from '../../ui/Avatar'
import PageLayout from '../../ui/PageLayout'
import { SmallPageHeading } from '../../ui/PageTitle'
import { PercentageBar } from '../../ui/PercentageBar'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser } from '../../ui/UserContext'
import { BarGraph } from '../analytics/components/BarChart'

interface GroupedInboxItems {
  user: User | null
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
    reasons: {
      total: number
      [key: string]: number
    }
  }
  inbox_items: any[]
}

interface Props {
  inbox_items: GroupedInboxItems[]
  signals: string[]
  stats: Array<{ date: string; stats: { total: number; pending: number; completed: number; dismissed: number } }>
  reasons: Array<{
    date: string
    stats: { total: number; [key: string]: number }
  }>
}

export default function InboxDashboard(props: Props) {
  const currentUser = useCurrentUser()
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  const totals = useMemo(() => {
    return props.stats.reduce(
      (acc, curr) => {
        return {
          total: acc.total + curr.stats.total,
          pending: acc.pending + curr.stats.pending,
          completed: acc.completed + curr.stats.completed,
          dismissed: acc.dismissed + curr.stats.dismissed
        }
      },
      { total: 0, pending: 0, completed: 0, dismissed: 0 }
    )
  }, [props.stats])

  return (
    <PageLayout size="full" flush gap={0} maxH="100%" minH="300px">
      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
          {canViewAsMember ? (
            <Tabs size="sm" variant="subtle" isManual defaultIndex={0} index={0}>
              <TabList>
                <Tab>Dashboard</Tab>
                <Tab as="a" href={projectPath('/inbox')}>
                  Inbox
                </Tab>
                <Tab as="a" href={projectPath('/inbox/unassigned')}>
                  Unassigned
                </Tab>
              </TabList>
            </Tabs>
          ) : (
            <SmallPageHeading>Inbox Dashboard</SmallPageHeading>
          )}
        </Flex>
      </TopBarContent>

      <Flex height="100%">
        <Stack padding={6} spacing={8} flex="1" overflow="auto">
          <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
            <Stack spacing={0}>
              <Text fontSize="sm" fontWeight="semibold">
                {greeting()}, {currentUser.firstName} 👋
              </Text>
              <Text fontSize="sm" color="gray.500">
                Here's the latest across your team's inboxes
              </Text>
            </Stack>
          </Flex>
          <Grid gridTemplateColumns="1fr 1px 1fr 1px 1fr 1px 1fr" gap={[2, 4, 6, 10]}>
            <StatCard
              title="Total leads"
              value={totals.total}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.total }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Pending"
              value={totals.pending}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.pending }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Engaged"
              value={totals.completed}
              series={props.stats.map((day) => ({ timestamp: day.date, count: day.stats.completed }))}
            />
            <Box width="1px" bg="gray.200" />
            <StatCard
              title="Dismissed"
              value={totals.dismissed}
              series={props.reasons.map((day) => ({ timestamp: day.date, ...day.stats }))}
            />
          </Grid>

          <Stack spacing={3}>
            {props.inbox_items.map((group) => (
              <Stack
                key={group.user?.id || 'unassigned'}
                spacing={4}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="lg"
              >
                <Flex alignItems="center" gap={8} px={3} py={2}>
                  <Flex flex="1" minW="200px" maxW="400px" alignItems="center" gap={2}>
                    {group.user ? (
                      <>
                        <Avatar size="sm" src={group.user.image} name={group.user.name || group.user.email} />
                        <Stack spacing={0}>
                          <Text fontSize="sm" fontWeight="semibold">
                            {group.user.name || group.user.email}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            Last active <TimeAgo time={group.user.last_app_interaction_at} />
                          </Text>
                        </Stack>
                      </>
                    ) : (
                      <Text fontSize="sm" fontWeight="semibold">
                        Unassigned
                      </Text>
                    )}
                  </Flex>

                  {group.stats && (
                    <Grid gridTemplateColumns="repeat(3, 1fr)" flex="none" alignItems="center" gap={2}>
                      <StatCard
                        title="Pending"
                        value={group.stats.pending}
                        size="sm"
                        borderLeft="1px solid"
                        borderColor="gray.200"
                        px={3}
                      />
                      <StatCard
                        title="Engaged"
                        value={group.stats.completed}
                        size="sm"
                        borderLeft="1px solid"
                        borderColor="gray.200"
                        px={3}
                      />
                      <StatCard
                        title="Dismissed"
                        value={group.stats.dismissed}
                        size="sm"
                        borderLeft="1px solid"
                        borderColor="gray.200"
                        px={3}
                      />
                    </Grid>
                  )}

                  {group.stats && (
                    <Box flex="1" minWidth="100px" maxWidth="300px" height="6px">
                      <PercentageBar
                        label="Leads"
                        categories={{
                          pending: group.stats.pending,
                          completed: group.stats.completed,
                          ...group.stats.reasons
                        }}
                        dataKeys={['pending', 'completed'].concat(Object.keys(group.stats.reasons))}
                        colors={{
                          completed: 'var(--chakra-colors-teal-600)',
                          dismissed: 'var(--chakra-colors-slate-600)',
                          pending: '#f0bf00',
                          ...Object.fromEntries(
                            Object.keys(group.stats.reasons).map((reason, index) => {
                              const slateShade = -index * 200 + 600
                              return [reason, `var(--chakra-colors-gray-${slateShade})`]
                            })
                          )
                        }}
                      />
                    </Box>
                  )}

                  <Button
                    as={Link}
                    href={projectPath(`/inbox/${group.user?.email || 'unassigned'}`)}
                    size="sm"
                    variant="outline"
                    rightIcon={<IconArrowRight size={15} />}
                    iconSpacing={2}
                    ml="auto"
                  >
                    View
                  </Button>
                </Flex>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Flex>
    </PageLayout>
  )
}

const statSizes = {
  sm: {
    label: 'xs',
    value: 'sm'
  },
  md: {
    label: '13px',
    value: 'lg'
  }
}

interface StatCardProps extends FlexProps {
  title: string
  value: number
  size?: 'sm' | 'md'
  series?: BarGraphSeries[]
}

interface BarGraphSeries {
  timestamp: string
  [key: string]: string | number
}

function StatCard({ title, value, size = 'md', series, ...props }: StatCardProps) {
  const yDataKeys = series ? Object.keys(series[0]).filter((key) => key !== 'timestamp') : []
  const label = yDataKeys.length <= 1 ? title : Object.fromEntries(yDataKeys.map((key) => [key, capitalize(key)]))

  return (
    <Flex maxW="100%" minW={series ? '100px' : '60px'} flexDirection="column" gap={1} {...props}>
      <Text fontSize={statSizes[size].label} color="gray.500">
        {title}
      </Text>
      <Heading size={statSizes[size].value} fontWeight="semibold">
        {value}
      </Heading>
      {series && (
        <BarGraph
          label={label}
          data={series}
          height={40}
          xDataKey="timestamp"
          yDataKeys={yDataKeys}
          xAxis={false}
          yAxis={false}
          legend={false}
          colorScheme="purple"
          monochrome
          minPointSize={1}
          // manually specify the y domain so the bars aren't full height when there's no data
          yDomain={!value ? [0, 100] : undefined}
        />
      )}
    </Flex>
  )
}
