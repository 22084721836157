import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useBoolean
} from '@chakra-ui/react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { post } from '../../../../lib/api'
import router from '../../../../lib/router'
import { AccountView } from '../../../../types/AccountView'
import { useSpaces } from '../../../data/use-spaces'
import { projectPath } from '../../../ui/ProjectsContext'
import SelectInput from '../../../ui/SelectInput'
import { accountViewPath } from '../lib/list-paths'

type CloneViewModalProps = {
  view: AccountView
  saveAs?: boolean
  onClose: () => void
  onComplete?: () => void
}

function CloneViewModal({ view, saveAs, onClose, onComplete }: CloneViewModalProps) {
  const [isLoading, setIsLoading] = useBoolean(false)
  const [name, setName] = useState<string>(`${view.name} (copy)`)
  const initialFocusRef = useRef(null)
  const [spaceId, setSpaceId] = useState<AccountView['space_id']>(view.space_id)
  const { data, isLoading: isLoadingSpaces } = useSpaces({ ownership: 'scoped' })

  const spaces = useMemo(() => data?.spaces ?? [], [data?.spaces])

  const spaceOptions = useMemo(() => {
    return [{ id: '', name: 'No space' }, ...spaces]
  }, [spaces])

  const handleNameChange = useCallback((e) => {
    setName(e.target.value)
  }, [])

  const handleClone = useCallback(() => {
    const query = saveAs ? window.location.search : ''
    const clonePath = projectPath(`/views/${view.id}/clone${query}`)

    const payload = {
      name: name.trim(),
      space_id: spaceId || null
    }
    setIsLoading.on()

    post<{ account_view: AccountView }>(clonePath, { account_view: payload }).then((res) => {
      setIsLoading.off()
      onClose()
      onComplete?.()
      router.visit(accountViewPath(res.account_view))
    })
  }, [view, name, spaceId, saveAs, onClose, onComplete, setIsLoading])

  return (
    <Modal isOpen initialFocusRef={initialFocusRef} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {saveAs ? 'Save as...' : 'Clone list'}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody paddingBottom={8}>
          <Stack spacing={8}>
            <Text fontSize="sm">
              You are {saveAs ? 'saving a new version of the list' : 'cloning the list'}{' '}
              <Text as="span" fontWeight="semibold">
                {view.name}
              </Text>
              . You can edit filters, columns, and other settings later, too.
            </Text>

            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialFocusRef}
                fontSize="sm"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
              />
            </FormControl>

            {isLoadingSpaces ? (
              <Flex height="32px" alignItems="center">
                <Spinner color="gray.400" size="sm" thickness="1.5px" />
              </Flex>
            ) : (
              spaces.length > 0 && (
                <FormControl mt={4}>
                  <FormLabel>Space (optional)</FormLabel>
                  <SelectInput
                    size="md"
                    variant="outline"
                    items={spaceOptions}
                    selectedItem={spaceOptions.find((space) => space.id === spaceId)}
                    onSelectedItemChange={({ selectedItem }) => setSpaceId(selectedItem?.id || null)}
                    itemToString={(item) => item?.name}
                    placeholder="Select a space"
                    popperOptions={{ matchWidth: true }}
                  />
                </FormControl>
              )
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>
          <Button size="sm" isLoading={isLoading} colorScheme="purple" onClick={handleClone}>
            Create list
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CloneViewModal
