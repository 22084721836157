import React, { useMemo } from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ChartTooltip } from '../pages/analytics/components/ChartTooltip'
import { constructCategoryColors } from '../pages/analytics/components/colors'

interface PercentageBarProps {
  categories: Record<string, number>
  label?: string
  dataKeys?: string[]
  colors?: Record<string, string>
}

export function PercentageBar({ categories, label, dataKeys, colors }: PercentageBarProps) {
  const categoryNames = dataKeys || Object.keys(categories)
  const defaultColors = constructCategoryColors(categoryNames)

  const data = useMemo(() => {
    const obj: Record<string, number> = {}

    for (const name of categoryNames) {
      const count = categories[name]
      if (count > 0) {
        obj[name] = count
      }
    }

    return [obj]
  }, [categories, categoryNames])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        layout="vertical"
        stackOffset="expand"
        barCategoryGap="100%"
      >
        <XAxis type="number" domain={[0, 1]} hide />
        <YAxis type="category" hide />
        <Tooltip
          contentStyle={{
            color: 'var(--chakra-colors-gray-700)',
            fontSize: 14,
            border: 'none',
            borderRadius: '4px',
            boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 12%)`
          }}
          allowEscapeViewBox={{ y: true }}
          formatter={(value: string) => Number(value).toLocaleString()}
          labelFormatter={label ? (_label: string) => label : undefined}
          wrapperStyle={{ outline: 'none', zIndex: 1000 }}
          content={<ChartTooltip />}
        />
        {categoryNames.map((dataKey, index) => (
          <Bar
            dataKey={dataKey}
            fill={colors?.[dataKey] || defaultColors.get(dataKey)}
            key={dataKey}
            stackId="stack"
            radius={[
              index === 0 ? 3 : 0, // left top
              index === categoryNames.length - 1 ? 3 : 0, // right top
              index === categoryNames.length - 1 ? 3 : 0, // right bottom
              index === 0 ? 3 : 0 // left bottom
            ]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
