import {
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  HStack,
  Img,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconBulb, IconCircleCheck } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { BTFlow } from '../../../ui/billing-banners/accounts-banner'
import { PricingForm } from '../../../ui/PricingForm'
import img from '../assets/billing-chart.svg'
import { plansV4 } from '../PricingTable'
import { KoalaSubscription } from '../show'
import { ReferralProgramModal } from './referral-program'

interface Props {
  subscription: KoalaSubscription
  skipPricing?: boolean
  onBTFlow?: () => void
}

function V3Plan(props: Props & { planName: 'starter' | 'business'; onClose: () => void }) {
  const nextPlan = plansV4[props.planName]
  const [priceVariation, setPriceVariation] = useState('annual')
  const [isRedirecting, _setIsRedirecting] = useState(false)

  const shouldShowPricing = useMemo(() => {
    return !props.skipPricing
  }, [props.skipPricing])

  return (
    <Stack
      w="100%"
      spacing="4"
      _first={{
        borderRight: '1px solid #E2E8F0'
      }}
      _last={{
        borderRight: 'none'
      }}
    >
      <Stack spacing="1" px={8}>
        <Heading size="md" fontWeight="semibold">
          Upgrade to {nextPlan.title}
        </Heading>

        {props.skipPricing && (nextPlan.annualMonthlyPrice === 'custom' || nextPlan.basePrice === 'custom') && (
          <>
            <Flex>
              <Text fontSize="sm">Reach out for a quote.</Text>
            </Flex>
          </>
        )}
      </Stack>

      <Stack spacing="8" px="8" w="100%">
        {shouldShowPricing && (
          <Stack>
            {typeof nextPlan.annualMonthlyPrice === 'number' && typeof nextPlan.basePrice === 'number' && (
              <HStack spacing="0" alignItems="baseline">
                <Heading size="xl">
                  {priceVariation === 'annual' ? `$${nextPlan.annualMonthlyPrice}` : `$${nextPlan.basePrice}`}
                </Heading>
                <HStack spacing="0.5" alignItems={'baseline'}>
                  <Text fontSize="sm">/mo</Text>
                  {priceVariation === 'annual' && <Text fontSize="xs">(billed anually)</Text>}
                </HStack>
              </HStack>
            )}

            {typeof nextPlan.annualMonthlyPrice === 'number' && typeof nextPlan.basePrice === 'number' && (
              <Flex>
                <Button
                  size="xs"
                  variant={'ghost'}
                  colorScheme={priceVariation === 'annual' ? undefined : 'purple'}
                  leftIcon={priceVariation === 'annual' ? undefined : <IconBulb size={16} />}
                  onClick={() => {
                    if (priceVariation === 'annual') {
                      setPriceVariation('monthly')
                    } else {
                      setPriceVariation('annual')
                    }
                  }}
                >
                  or pay{' '}
                  {priceVariation === 'annual'
                    ? `$${nextPlan.basePrice} monthly`
                    : `$${nextPlan.annualMonthlyPrice}/mo billed yearly`}
                </Button>
              </Flex>
            )}

            {!props.skipPricing && (nextPlan.annualMonthlyPrice === 'custom' || nextPlan.basePrice === 'custom') && (
              <>
                <HStack spacing="1" alignItems="baseline">
                  <Heading size="xl">Custom</Heading>
                </HStack>
                <Flex pt="1">
                  <Text fontSize="xs">Billed anually</Text>
                </Flex>
              </>
            )}
          </Stack>
        )}

        <Stack spacing="1" w="100%">
          {!props.skipPricing && (
            <Collapse in={isRedirecting}>
              <Center py="4">
                <Heading size="xs">Redirecting you to Stripe to finish your transaction</Heading>
              </Center>
            </Collapse>
          )}

          <Stack>
            <Button
              mt={shouldShowPricing ? 0 : '2'}
              colorScheme={'purple'}
              variant={'solid'}
              isLoading={isRedirecting}
              onClick={(_e) => {
                props.onBTFlow?.()
              }}
            >
              Talk to Sales
            </Button>
          </Stack>
        </Stack>

        <List fontSize="sm" spacing="2" pt={props.skipPricing ? 0 : '4'}>
          {nextPlan.title === 'Starter' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in Free, plus:
            </Heading>
          )}

          {nextPlan.title === 'Pro' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in Starter, plus:
            </Heading>
          )}

          {nextPlan.title === 'Business' && (
            <Heading size="xs" fontWeight="semibold">
              Everything in our Self Service plans, plus:
            </Heading>
          )}

          {nextPlan.features.map((feature) => (
            <ListItem key={feature} display="flex">
              <ListIcon as={IconCircleCheck} boxSize={4} color="green.500" marginY={0.5} />
              {feature}
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  )
}

export function V3CheckoutModal(props: Props & { onClose: () => void; upgrade_to?: 'business' }) {
  const [showPricingForm, setShowPricingForm] = useState(false)
  const btToggle = useDisclosure()

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      size={props.subscription.plan === 'starter' || props.upgrade_to ? 'xl' : '4xl'}
    >
      <ModalOverlay backdropFilter="blur(8px)" />

      <ModalContent rounded="2xl" pb="8">
        <ModalCloseButton />
        <ModalBody p="0" w="100%">
          {btToggle.isOpen && (
            <Stack p="4">
              <BTFlow />
            </Stack>
          )}

          {!btToggle.isOpen && (
            <>
              <Img src={img} w="100%" />
              <Stack w="100%" px="8" pt="4">
                <HStack w="100%" justifyContent={'center'} alignItems="flex-start" pb="6">
                  {!props.upgrade_to && props.subscription.plan !== 'starter' && (
                    <V3Plan planName="starter" {...props} onBTFlow={btToggle.onOpen} />
                  )}
                  {!props.upgrade_to && <V3Plan planName="business" {...props} onBTFlow={btToggle.onOpen} />}
                  {props.upgrade_to && (
                    <V3Plan
                      skipPricing={props.upgrade_to === 'business'}
                      planName={props.upgrade_to}
                      {...props}
                      onBTFlow={btToggle.onOpen}
                    />
                  )}
                </HStack>

                {props.upgrade_to !== 'business' && (
                  <Button
                    colorScheme="purple"
                    variant="link"
                    width="auto"
                    onClick={() => {
                      setShowPricingForm(true)
                    }}
                  >
                    Got questions? Send us a message
                  </Button>
                )}

                {showPricingForm && (
                  <PricingForm
                    onClose={() => {
                      setShowPricingForm(false)
                    }}
                  />
                )}
              </Stack>
              {/* only show referral option to free, since other plans are not limited on Accounts */}
              {props.subscription.plan === 'free' && props.upgrade_to !== 'business' && (
                <ReferralProgramModal>
                  <Center textAlign={'center'} py={2}>
                    <Link maxW="75%" fontWeight={'normal'} fontSize="xs" color="gray.500">
                      Not ready to upgrade? Learn how you can unlock more accounts with Koala's referral program.
                    </Link>
                  </Center>
                </ReferralProgramModal>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

// alias the old component to use the new one
export const CheckoutModal = V3CheckoutModal
