import React, { useEffect, useState, useRef } from 'react'
import { Box, Progress, ScaleFade, Text, Flex, Icon, Button } from '@chakra-ui/react'
import { IconMinus, IconPencil, IconArrowsDiagonal } from '@tabler/icons-react'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { throttle } from 'lodash'

interface ImportListPromptProps {
  show?: boolean
  listIsRenamed?: boolean
  listId?: string
  onRenameListClick?: () => void
  listName?: string
}

export function ImportListPrompt(props: React.PropsWithChildren<ImportListPromptProps>) {
  const [promptMinimized, setPromptMinimized] = useState(false)
  const [importInfoLevelData, setImportInfoLevelData] = useState<number | undefined>(0)
  const [showImportOnStaticList, setShowImportOnStaticList] = useState(false)
  const listId = props.listId
  const listIsRenamed = props.listIsRenamed
  const subscription = useRef<SubscriptionEmitter>()

  const project = useCurrentProject()

  const handleImportInfo = React.useCallback(
    (message: { action: string; level?: number }) => {
      if (!project?.slug || !message) {
        return
      }
      setShowImportOnStaticList(true)
      if (message.action === 'finished' || message.action === 'timeout') {
        setImportInfoLevelData(100)
      } else {
        setImportInfoLevelData(message.level)
      }
    },
    [project?.slug]
  )

  const importInProgress = !importInfoLevelData || importInfoLevelData < 100

  const importInProgressText = props.listName
    ? 'Your companies list is being created. You can leave the page and your list will continue to run in the background.'
    : 'Your list is being created. You can leave the page or explore the Prospector and your list will continue to run in the background.'

  useEffect(() => {
    if (subscription.current || !project?.slug) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'StaticListsChannel',
      id: listId,
      project_slug: project.slug
    })

    const sub = subscription.current
    const update = throttle(handleImportInfo, 2000)
    sub.on('received', update)

    const unsub = () => {
      update.cancel()
      sub?.off('received', update)
      sub?.unsubscribe()
    }

    return () => {
      unsub()
      subscription.current = undefined
    }
  }, [listId, project?.slug, handleImportInfo])

  const handleMinMaxIconClick = () => {
    if (!promptMinimized) {
      setPromptMinimized(true)
    } else {
      setPromptMinimized(false)
    }
  }

  const showImport = (importInfoLevelData && importInProgress) || showImportOnStaticList || props.show

  return (
    <Box
      display="flex"
      justifyContent="center"
      position="absolute"
      bottom="32px"
      right={4}
      pointerEvents="none"
      zIndex={10}
    >
      <ScaleFade unmountOnExit in={showImport}>
        <Box
          display="flex"
          justifyContent="center"
          position="absolute"
          bottom="32px"
          right={4}
          pointerEvents="none"
          width="349px"
          zIndex={999}
          background="white"
        >
          <Box
            display="flex"
            flexDir="column"
            gap={3}
            alignItems="flex-start"
            zIndex="sticky"
            bg="white"
            fontSize="sm"
            rounded="lg"
            width="349px"
            padding={4}
            pointerEvents="auto"
            boxShadow="rgba(28, 40, 64, 0.06) 0px 0px 0px 1px inset, rgba(28, 40, 64, 0.12) 0px 4px 8px -4px, rgba(28, 40, 64, 0.16) 0px 4px 12px -2px"
            background="linear-gradient(180deg, rgba(77, 50, 228, 0.04) 0%, rgba(77, 50, 228, 0.00) 100%)"
          >
            <Flex direction="row" justify="space-between" alignItems="center" width="100%">
              {!promptMinimized && !props.listName && (
                <Text fontSize="md" fontWeight="600">
                  📋 Your list is being created
                </Text>
              )}
              {!promptMinimized && props.listName && (
                <Text fontSize="md" fontWeight="600">
                  {props.listName}
                </Text>
              )}
              {promptMinimized && !props.listName && (
                <Text fontSize="sm" fontWeight="400">
                  Importing {`${importInfoLevelData}%`}
                </Text>
              )}
              {promptMinimized && props.listName && (
                <Text fontSize="sm" fontWeight="400">
                  {props.listName}
                </Text>
              )}
              <Icon
                as={promptMinimized ? IconArrowsDiagonal : IconMinus}
                color="purple.500"
                onClick={() => handleMinMaxIconClick()}
              />
            </Flex>

            {!promptMinimized && (
              <>
                <Text fontSize="sm">{importInProgress ? `${importInProgressText}` : 'Your list is ready!'}</Text>
                <Flex direction="row" justify="space-between" alignItems="center" width="100%">
                  <Text fontSize="sm" fontWeight="600">
                    {props.listName ? 'Creating list' : 'Importing'}
                  </Text>
                  <Text fontSize="sm" fontWeight="600">
                    {`${importInfoLevelData}%`}
                  </Text>
                </Flex>
              </>
            )}
            <Progress
              colorScheme={importInProgress ? 'purple' : 'green'}
              size="xs"
              width="100%"
              value={importInfoLevelData}
            />
            {!promptMinimized && props.onRenameListClick && (
              <Flex direction="row" alignItems="center" width="100%" onClick={props.onRenameListClick}>
                {!listIsRenamed ? (
                  <Button
                    variant="link"
                    colorScheme="purple"
                    size="sm"
                    leftIcon={<IconPencil size={16} />}
                    iconSpacing={1}
                  >
                    Rename list
                  </Button>
                ) : (
                  <Button as="a" size="md" width="100%" colorScheme="purple" href={projectPath(`/lists/${listId}`)}>
                    View list
                  </Button>
                )}
              </Flex>
            )}
          </Box>
        </Box>
      </ScaleFade>
    </Box>
  )
}
