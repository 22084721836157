import { Flex, Spinner, useTheme } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { format } from 'friendly-numbers'
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { DateRange } from 'react-day-picker'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ChartLegend } from './ChartLegend'
import { ChartTooltip } from './ChartTooltip'
import { constructCategoryColors, constructMonochromeColors, defaultCategoricalColors } from './colors'
import { getGranularity, Period, prepareTimeseriesData, TimeseriesData } from './timeseries'

type FormatterFn = (value: any, name: string, _props: unknown) => string | [string, string]

export interface BarGraphProps {
  data?: BarData[]
  label: string | { [key: string]: string }
  period?: Period | DateRange | string
  isLoading?: boolean
  xDataKey?: string
  yDataKeys?: string[]
  height?: number
  xAxis?: boolean
  yAxis?: boolean
  yDomain?: [number, number]
  legend?: boolean
  colorScheme?: string
  monochrome?: boolean
  minPointSize?: number

  formatter?: FormatterFn | { [key: string]: FormatterFn }
  xFormatter?: (value: any) => string
  yFormatter?: (value: any) => string
}

const emptyArray = []

const formatDay = (timestamp) => dayjs.utc(timestamp).format('ddd, MMM D, YYYY')
const formatHour = (timestamp) => dayjs.utc(timestamp).local().format('ddd, MMM D, ha')
const shortDay = (timestamp) => dayjs.utc(timestamp).format('MMM D')
const shortMonth = (timestamp) => dayjs.utc(timestamp).format('MMM YYYY')
const shortHour = (timestamp) => dayjs.utc(timestamp).local().format('ha')

const xFormatters = {
  hour: shortHour,
  day: shortDay,
  month: shortMonth
}

const tooltipFormatters = {
  hour: formatHour,
  day: formatDay,
  month: shortMonth
}

export interface BarData {
  [key: string]: number | string
}

export function BarGraph(props: BarGraphProps) {
  const theme = useTheme()
  const formatterFn = props.formatter
  const { label, period, minPointSize } = props
  const dataKeys = props.yDataKeys ?? emptyArray

  const data = useMemo(() => {
    if (props.data?.some((item) => 'timestamp' in item)) {
      return prepareTimeseriesData(props.data as TimeseriesData[], dataKeys, 'identity', period)
    } else {
      return props.data ?? emptyArray
    }
  }, [props.data, dataKeys, period])

  const keysWithData = useMemo(() => {
    if (dataKeys.length === 1) {
      return dataKeys
    } else {
      return dataKeys.filter((key) => data.some((d) => get(d, `${key}.value`)))
    }
  }, [dataKeys, data])

  const [barProps, setBarProps] = React.useState<{ hover: null | string; selected: string[] }>({
    hover: null,
    selected: []
  })

  const handleLegendMouseEnter = useCallback((e) => {
    const key = e.dataKey
    setBarProps((prev) => (prev[key] ? prev : { ...prev, hover: key }))
  }, [])

  const handleLegendMouseLeave = useCallback(() => {
    setBarProps((prev) => ({ ...prev, hover: null }))
  }, [])

  const selectBar = useCallback(
    (e) => {
      setBarProps((prev) => ({
        ...prev,
        selected: prev.selected?.includes(e.dataKey)
          ? prev.selected.filter((k) => k !== e.dataKey)
          : prev.selected.length + 1 === keysWithData.length
            ? []
            : [...(prev.selected ?? []), e.dataKey],
        hover: null
      }))
    },
    [keysWithData]
  )

  const defaultFormatter = React.useCallback(
    (value, name, _props) => {
      name = name?.split('.')?.[0]
      if (typeof formatterFn === 'function') {
        return formatterFn(value, name, _props)
      } else if (formatterFn && typeof formatterFn[name] === 'function') {
        return formatterFn[name](value, name, _props)
      } else {
        return [value.toLocaleString()] as any as [string, string]
      }
    },
    [formatterFn]
  )

  if (props.isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" width="100%" height={props.height || 280}>
        <Spinner size="lg" color="gray.400" />
      </Flex>
    )
  }

  const granularity = getGranularity(period)
  const tooltipFormatter = tooltipFormatters[granularity] || tooltipFormatters.day
  const xFormatter = xFormatters[granularity] || xFormatters.day

  const colors = props.monochrome
    ? constructMonochromeColors(dataKeys, props.colorScheme)
    : constructCategoryColors(
        dataKeys,
        props.colorScheme ? [theme.colors[props.colorScheme]['500']] : defaultCategoricalColors
      )

  return (
    <ResponsiveContainer width="100%" height={props.height || 280}>
      <BarChart
        key={`${JSON.stringify(props.label)}:${data.length}`}
        data={data}
        margin={{
          top: 10,
          right: 0,
          bottom: 5,
          left: 0
        }}
        barCategoryGap="15%"
      >
        <Tooltip
          cursor={{ opacity: 0.25 }}
          allowEscapeViewBox={{ y: true }}
          wrapperStyle={{ outline: 'none', zIndex: 1000 }}
          labelFormatter={tooltipFormatter}
          formatter={defaultFormatter}
          content={<ChartTooltip colors={colors} />}
        />

        {props.xAxis !== false && (
          <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />
        )}

        <XAxis
          dataKey={props.xDataKey || 'name'}
          hide={props.xAxis === false}
          tickFormatter={props.xFormatter || xFormatter}
          tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
          stroke={theme.colors.gray['200']}
          padding={{ right: 4 }}
          interval="preserveStartEnd"
          tickMargin={10}
        />
        <YAxis
          hide={props.yAxis === false}
          width={42}
          domain={props.yDomain}
          tickFormatter={props.yFormatter || ((v) => format(v, { formattedDecimals: 1 }))}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
        />

        {keysWithData.map((key, i) => {
          const dataKey = `${key}.value`
          return (
            <Bar
              stackId={'a'}
              key={key}
              hide={barProps.selected.length > 0 && !barProps.selected.includes(dataKey)}
              name={typeof label === 'string' ? label : label?.[key]}
              dataKey={dataKey}
              fill={colors.get(key)}
              fillOpacity={Number(
                barProps.hover === dataKey ||
                  !barProps.hover ||
                  (barProps.selected.length && !barProps.selected.includes(barProps.hover))
                  ? 1
                  : 0.25
              )}
              radius={i === dataKeys.length - 1 ? [2, 2, 0, 0] : undefined}
              maxBarSize={100}
              minPointSize={minPointSize}
              isAnimationActive={false}
            />
          )
        })}

        {keysWithData.length > 1 && props.legend !== false && (
          <Legend
            verticalAlign="top"
            height={40}
            content={
              <ChartLegend
                onClick={selectBar}
                onMouseOver={handleLegendMouseEnter}
                onMouseOut={handleLegendMouseLeave}
              />
            }
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
