import { Box, Button, Flex, IconButton, Link, Tooltip, useCheckboxGroup, useDisclosure } from '@chakra-ui/react'
import { IconListSearch, IconTableOptions } from '@tabler/icons-react'
import React, { useEffect, useMemo } from 'react'
import { Account } from '../../../../../types/Account'
import { ProfileRecord } from '../../../../../types/Profile'
import { useFacets } from '../../../../data/use-facets'
import { useProfileSearch } from '../../../../data/use-profile-search'
import { BulkActionBar } from '../../../../ui/BulkActionBar'
import EmptyState from '../../../../ui/EmptyState'
import { projectPath } from '../../../../ui/ProjectsContext'
import { TableFooter } from '../../../../ui/TableFooter'
import { AddToListModal } from '../../../lists/components/AddToListModal'
import { ProfileList } from '../../../profiles/components/profile-list'
import { SearchBar } from '../../facets/search-bar'
import { FilterPreview } from '../FilterPreview'
import { BulkAddToSequenceMenu } from '@app/components/ui/BulkAddToSequenceMenu'
import { BulkAddToCrmModal } from '@app/components/ui/BulkAddToCrmModal'

interface Props {
  defaultColumns?: string[]
  account: Account
}

const emptyArray = []
const globalDefaults = ['last_seen_at', 'page_views_trend', 'focus_time_trend', 'signal', 'sources']

export function People(props: Props) {
  const columns = (props.defaultColumns || globalDefaults).filter((k) => {
    if (['company', 'title', 'auto_icp_account_score.fit_grade_letter'].includes(k)) return false
    return true
  })

  const facets = useFacets({
    range: 'any',
    facetCloudPath: '/profiles/facet-cloud',
    sortBy: 'last_seen'
  })

  // fetch profiles for this company
  const { data, isLoading } = useProfileSearch({
    accountId: props.account.id,
    columns,
    queryString: facets.queryString
  })

  const apps = useMemo(() => Object.values(data?.apps || {}), [data?.apps])
  const profiles = data?.profiles || emptyArray

  const checkboxes = useCheckboxGroup()
  const selectedIds = checkboxes.value as string[]
  const selectedProfiles = profiles.filter((obj) => selectedIds.includes(obj.id))

  useEffect(() => {
    checkboxes.setValue([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles])

  return (
    <Box>
      <Flex justifyContent="space-between" gap={4} px={0} pb={3}>
        <FilterPreview
          {...facets}
          facetValuesPath={`/profiles/facet-values?facets[account_id]=${props.account.id}`}
          kind="profile"
          topFilters={emptyArray}
          shouldShowCompanyFilters={false}
          shouldShowICPFilters={false}
          shouldShowSourceFilter={(data?.profile_sources || emptyArray).length > 1}
          apps={data?.apps}
        />

        <Flex flexShrink={0} width="220px" gap={1}>
          <SearchBar size="sm" debounce={300} value={facets.query} onChange={facets.setQuery} />

          <Tooltip label="Manage default columns">
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Manage default columns"
              as={Link}
              href={projectPath(`profiles/layouts`)}
              isExternal
              icon={<IconTableOptions size={16} />}
            />
          </Tooltip>
        </Flex>
      </Flex>

      {isLoading || profiles.length > 0 ? (
        <>
          <ProfileList
            profiles={profiles}
            isLoading={isLoading}
            selected={selectedIds}
            getCheckboxProps={checkboxes.getCheckboxProps}
            onSelectAll={() => {
              checkboxes.setValue((prev) => (prev.length === profiles.length ? [] : profiles.map((p) => p.id)))
            }}
            // default page size
            rowCount={20}
            apps={apps}
            loadingColumns={isLoading ? columns : emptyArray}
            range="any"
            facetParams={facets}
            columns={columns}
            sortingBy={facets.sortBy}
            onSortChange={facets.setSortBy}
            openLinksInNewTab
            personCellMode="person-summary"
          />

          {data?.page_meta && profiles.length > 0 && (
            <TableFooter
              word="person"
              pageMeta={data?.page_meta}
              page={(facets.page ?? 1) as number}
              setPage={facets.setPage}
              px={[3, 4]}
              scrollToTop={false}
              sticky
            />
          )}
        </>
      ) : (
        <Box bg="background.light" rounded="xl">
          <EmptyState
            size="sm"
            heading={facets.isFiltering ? 'No people match these filters' : 'No people tracked for this account yet'}
            description={facets.isFiltering ? 'Adjust your filters to see other people in this account' : undefined}
            icon={IconListSearch}
            ctaText={facets.isFiltering ? 'Clear filters' : undefined}
            onClick={facets.clearFilters}
          />
        </Box>
      )}

      {!isLoading && (
        <BulkActionBar selectionCount={selectedIds.length} onRemoveSelection={() => checkboxes.setValue([])}>
          <BulkAddToList selectedIds={selectedIds} />

          <BulkAddToCrmModal
            selectedIds={selectedIds}
            apps={data?.apps || {}}
            recordType="profile"
            onImport={() => checkboxes.setValue([])}
          />

          <BulkAddToSequenceMenu
            apps={data?.apps || {}}
            selectedRecords={selectedProfiles as ProfileRecord[]}
            recordType="Profile"
            recordLabel="people"
          />
        </BulkActionBar>
      )}
    </Box>
  )
}

interface BulkAddToListProps {
  selectedIds: string[]
  onComplete?: (profiles: ProfileRecord[]) => void
}

function BulkAddToList(props: BulkAddToListProps) {
  const disclosure = useDisclosure()

  return (
    <>
      <Button size="sm" variant="outline" onClick={disclosure.onOpen}>
        Add to List
      </Button>
      <AddToListModal {...disclosure} recordIds={props.selectedIds} kind="profile" />
    </>
  )
}
