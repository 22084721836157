import { concurrentGET } from '../lib/api'
import { projectPath } from '../components/ui/ProjectsContext'
import { useCurrentProject } from '../components/ui/ProjectsContext'
import { useQuery } from 'react-query'

export interface AIAgent {
  id: string
  slug: string
  name: string
  question: string
  created_at: string
  updated_at: string
  created_by_id: string
  updated_by_id: string
}

interface AIAgentsResponse {
  agents: AIAgent[]
  my_agents: AIAgent[]
}

export function useAIAgents() {
  const project = useCurrentProject()
  const basePath = '/settings/agents.json'

  const path = projectPath(basePath)

  return useQuery<AIAgentsResponse>(['ai-agents', { projectId: project?.id }], () =>
    concurrentGET<AIAgentsResponse>(path)
  )
}
