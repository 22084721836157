import { AdminBreadcrumb } from '..'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Switch,
  Text,
  Textarea
} from '@chakra-ui/react'
import { Project } from '@app/types/Project'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { StepIcon } from '../../../ui/StepIcon'
import React, { FormEvent, useCallback, useState, useRef } from 'react'
import CollapsibleBucketTable from './buckets_table'
import MiniScoreDistribution from './score_distribution'
import { BucketSummary, DistributionBar, DisqualifiedSummary } from './buckets_summary'
import ProjectSelector from './project_selector'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { Bucket } from './types'
import { Apps } from '@app/types/App'
import { useFacets } from '../../../data/use-facets'
import router from '../../../../lib/router'
import { FacetFilters } from '../../../pages/accounts'

interface Props {
  apps: Apps
  projects: Project[]
  project: Project
  buckets_settings: Record<string, { name: string; threshold: number }>
  sample_size: number
  disqualify?: FacetFilters
  criteria?: string
  high?: FacetFilters
  medium?: FacetFilters
  low?: FacetFilters
  free_mode?: boolean
  audience?: 'all' | 'converted' | 'crm'
}

export default function Show(props: Props) {
  const [buckets, setBuckets] = useState<Bucket[]>([])
  const [disqualified, setDisqualified] = useState<[]>([])
  const [totalAccounts, setTotalAccounts] = useState<number>(0)
  const [totalMatching, setTotalMatching] = useState<number>(0)
  const [totalDisqualified, setTotalDisqualified] = useState<number>(0)
  const [totalNotScored, setTotalNotScored] = useState<number>(0)
  const [totalPossiblePoints, setTotalPossiblePoints] = useState<number>(0)
  const [pointsHistogram, setPointsHistogram] = useState<Array<{ key: number; doc_count: number }>>([])
  const [selectedProject, setSelectedProject] = useState<Project | null>(props.project)
  const [isLoading, setIsLoading] = useState(false)
  const [audience, setAudience] = useState<'all' | 'converted' | 'crm'>(props.audience || 'all')
  const controllerRef = useRef<AbortController>()

  const initialBucketsSettings = {
    B1: { name: 'Not a fit', threshold: 50 },
    B2: { name: 'Fair', threshold: 70 },
    B3: { name: 'Good', threshold: 80 },
    B4: { name: 'Fantastic', threshold: 100 }
  }

  const [bucketsSettings, setBucketsSettings] = useState<Record<string, { name: string; threshold: number }>>(
    props.buckets_settings || initialBucketsSettings
  )

  const [sampleSize, setSampleSize] = useState<number>(props.sample_size || 15)

  const [freeMode, setFreeMode] = useState(props.free_mode || false)
  const [criteria, setCriteria] = useState(props.criteria)

  const high = useFacets({
    facetCloudPath: '/accounts/facet-cloud?seen=0',
    facet_filters: props.high || {}
  })

  const medium = useFacets({
    facetCloudPath: '/accounts/facet-cloud?seen=0',
    facet_filters: props.medium || {}
  })

  const low = useFacets({
    facetCloudPath: '/accounts/facet-cloud?seen=0',
    facet_filters: props.low || {}
  })

  const disqualify = useFacets({
    facetCloudPath: '/accounts/facet-cloud?seen=0',
    facet_filters: props.disqualify || {}
  })

  const startPreview = useCallback(
    (_: FormEvent) => {
      if (!selectedProject) {
        toast.error('Please select an project')
        return
      }

      controllerRef.current?.abort()
      controllerRef.current = new AbortController()

      setIsLoading(true)
      post(`/admin/icp-playground/preview/${selectedProject.slug}`, {
        free_mode: freeMode,
        criteria: criteria,
        high: high.facetFilters,
        medium: medium.facetFilters,
        low: low.facetFilters,
        disqualify: disqualify.facetFilters,
        buckets_settings: bucketsSettings,
        sample_size: sampleSize,
        audience: audience
      })
        .then((data: any) => {
          console.log(data)
          const buckets = data.score_ranges.buckets
            .sort((a, b) => {
              return (b.from || 0) - (a.from || 0) + (b.to || b.from + 1) - (a.to || a.from + 1)
            })
            .map((bucket: any) => ({
              key: bucket.key,
              doc_count: bucket.doc_count,
              from: bucket?.from,
              to: bucket?.to,
              max_score: bucket.sample_accounts.hits.max_score || 0,
              matching_percent: Math.round((bucket.doc_count / data.total_matching) * 100),
              total_percent: Math.round((bucket.doc_count / data.total_accounts) * 100),
              sample_accounts: bucket.sample_accounts.hits.hits.map((hit: any) => ({
                id: hit._id,
                company: hit._source.company,
                score: hit._score
              }))
            }))
          setBuckets(buckets)
          setTotalMatching(data.total_matching)
          setPointsHistogram(data.points_histogram.buckets)
          setDisqualified(data.disqualified)
          setTotalAccounts(data.total_accounts)
          setTotalDisqualified(data.total_disqualified)
          setTotalNotScored(data.total_not_scored)
          setTotalPossiblePoints(data.total_possible_points)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [selectedProject, freeMode, criteria, high, medium, low, disqualify, bucketsSettings, sampleSize, audience]
  )

  return (
    <PageLayout size="full">
      <HStack>
        <PageTitle flex="1">ICP Playground</PageTitle>
      </HStack>

      <AdminBreadcrumb paths={[{ path: '/admin/icp-playground', title: 'ICP Playground' }]} />
      <Stack>
        <HStack w="full">
          {' '}
          <ProjectSelector
            projects={props.projects}
            selectedProject={selectedProject}
            onChange={(project) => {
              setSelectedProject(project)
              router.visit(`/projects/${project?.slug}/icp_playground`)
            }}
          />
        </HStack>
        <FormControl flex="none" w="auto" size="sm" display="flex" alignItems="center" gap={2}>
          <FormLabel htmlFor="mode" m="0">
            Fixed Points
          </FormLabel>
          <Switch id="free-mode" isChecked={freeMode} onChange={(e) => setFreeMode(e.target.checked)} />
          <FormLabel htmlFor="mode" m="0">
            Free points
          </FormLabel>
        </FormControl>
        <Stack onSubmit={startPreview}>
          <Box>
            <Heading size="md" mt={10} mb={4}>
              Score criteria
            </Heading>
            <Stack>
              <Heading size="sm" lineHeight={1}>
                Build your Ideal Customer Profile (ICP)
              </Heading>
              {freeMode ? (
                <Stack>
                  <Textarea h="500px" name="criteira" value={criteria} onChange={(e) => setCriteria(e.target.value)} />
                </Stack>
              ) : (
                <Stack>
                  <HStack alignItems="flex-start">
                    <StepIcon step={1} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          High Importance
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          Start with their most important traits. This will count 3 points per traits when calculating
                          the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                  <FilterPreview
                    {...high}
                    apps={props.apps}
                    kind="account"
                    range={null}
                    canClearFilters={true}
                    shouldShowICPFilters={false}
                    shouldShowLastSeenFilter={false}
                    shouldShowUserAttributeFilters={false}
                    shouldShowCompanyFilters={true}
                    shouldShowStaticListFilters={true}
                    shouldShowListFilters={true}
                    shouldShowTraits={true}
                    shouldAllowFreeEntry={true}
                    hideFacetCounts={false}
                  />
                  <HStack alignItems="flex-start">
                    <StepIcon step={2} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          Medium Importance
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          Continue with traits that differentiate them. This will count 2 points per traits when
                          calculating the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                  <FilterPreview
                    {...medium}
                    apps={props.apps}
                    kind="account"
                    range={null}
                    canClearFilters={true}
                    shouldShowICPFilters={false}
                    shouldShowLastSeenFilter={false}
                    shouldShowUserAttributeFilters={false}
                    shouldShowCompanyFilters={true}
                    shouldShowStaticListFilters={true}
                    shouldShowListFilters={true}
                    shouldShowTraits={true}
                    shouldAllowFreeEntry={true}
                    hideFacetCounts={false}
                  />
                  <HStack alignItems="flex-start">
                    <StepIcon step={3} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          Low Importance
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          Finish with traits that would be good to have. This will count 1 point per traits when
                          calculating the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                  <FilterPreview
                    {...low}
                    apps={props.apps}
                    kind="account"
                    range={null}
                    canClearFilters={true}
                    shouldShowICPFilters={false}
                    shouldShowLastSeenFilter={false}
                    shouldShowUserAttributeFilters={false}
                    shouldShowCompanyFilters={true}
                    shouldShowStaticListFilters={true}
                    shouldShowListFilters={true}
                    shouldShowTraits={true}
                    shouldAllowFreeEntry={true}
                    hideFacetCounts={false}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
          <Box>
            <Heading size="sm" mt={10} mb={4}>
              Disqualify criteria
            </Heading>
            <Stack>
              <Heading size="sm" lineHeight={1}>
                Define what is outside your ICP
              </Heading>
              <HStack alignItems="flex-start">
                <StepIcon step={4} />
                <Stack spacing={4} maxWidth="600px" minWidth="250px">
                  <Stack spacing={1}>
                    <Heading size="sm" lineHeight={1}>
                      Make sure that accounts matching these remaing outside your ICP
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                      This will remove the matching accounts from scoring
                    </Text>
                  </Stack>
                </Stack>
              </HStack>
              <FilterPreview
                {...disqualify}
                apps={props.apps}
                kind="account"
                range={null}
                canClearFilters={true}
                shouldShowICPFilters={false}
                shouldShowLastSeenFilter={false}
                shouldShowUserAttributeFilters={false}
                shouldShowCompanyFilters={true}
                shouldShowStaticListFilters={true}
                shouldShowListFilters={true}
                shouldShowTraits={true}
                shouldAllowFreeEntry={true}
                hideFacetCounts={false}
              />
            </Stack>
          </Box>
          <Box>
            <Heading size="md" mt={100} mb={4}>
              Distribution Preview Settings
            </Heading>
            <Stack spacing={15}>
              <Heading size="sm">Label definition for points ranges</Heading>

              {Object.entries(bucketsSettings).map(([key, bucket], index) => (
                <Box key={key} mb={8} >
                  <FormControl mt={50} mb={2}>
                    <Box position="relative">
                      <Input
                        size="sm"
                        value={bucket.name}
                        onChange={(e) =>
                          setBucketsSettings((prev) => ({
                            ...prev,
                            [key]: { ...prev[key], name: e.target.value }
                          }))
                        }
                        position="absolute"
                        top="-30px"
                        left={`${index === 0 ? 0 : bucketsSettings[`B${index}`].threshold}%`} /* Align with left thumb */
                        width={`${
                          (index === Object.keys(bucketsSettings).length - 1
                            ? 100
                            : bucketsSettings[`B${index + 1}`].threshold) -
                          (index === 0 ? 0 : bucketsSettings[`B${index}`].threshold)
                        }%`} /* Dynamic width based on range */
                        textAlign="center"
                        bg="white"
                        zIndex="1"
                      />
                    </Box>
                  </FormControl>

                  {index <= Object.keys(bucketsSettings).length - 1 && (
                    <RangeSlider
                      aria-label={[`bucket-${index}`, `bucket-${index + 1}`]}
                      min={0}
                      max={100}
                      minStepsBetweenThumbs={1}
                      step={1}
                      value={[
                        index === 0 ? 0 : bucketsSettings[`B${index}`].threshold,
                        index === Object.keys(bucketsSettings).length - 1
                          ? 100
                          : bucketsSettings[`B${index + 1}`].threshold
                      ]}
                      onChange={(values) => {
                        setBucketsSettings((prev) => {
                          const newSettings = { ...prev }

                          if (index > 0) {
                            // Update the left thumb (threshold of the current bucket)
                            newSettings[`B${index}`] = {
                              ...newSettings[`B${index}`],
                              threshold: values[0]
                            }
                          }

                          // Update the right thumb (threshold of the next bucket)
                          if (index < Object.keys(bucketsSettings).length - 1) {
                            newSettings[`B${index + 1}`] = {
                              ...newSettings[`B${index + 1}`],
                              threshold: values[1]
                            }
                          }

                          return newSettings
                        })
                      }}
                    >
                      <RangeSliderTrack>
                        <RangeSliderFilledTrack />
                      </RangeSliderTrack>
                      <RangeSliderThumb index={0}>
                        <Box position="absolute" top="25px" fontSize="xs" color="black">
                          {index === 0 ? 0 : bucketsSettings[`B${index}`].threshold}%
                        </Box>
                      </RangeSliderThumb>
                      <RangeSliderThumb index={1}>
                        <Box position="absolute" top="25px" fontSize="xs" color="black">
                          {index === Object.keys(bucketsSettings).length - 1
                            ? 100
                            : bucketsSettings[`B${index + 1}`].threshold}
                          %
                        </Box>
                      </RangeSliderThumb>
                    </RangeSlider>
                  )}
                </Box>
              ))}
            </Stack>
          </Box>
          <Box>
            <Heading size="sm" mt={10} mb={4}>
              Audience Selection
            </Heading>
            <FormControl>
              <FormLabel>Choose audience to preview distribution</FormLabel>
              <Select value={audience} onChange={(e) => setAudience(e.target.value as 'all' | 'converted' | 'crm')}>
                <option value="all">All</option>
                <option value="converted">Converted (Closed Won)</option>
                <option value="crm">In CRM</option>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Stack spacing={4} mt={4}>
              <Heading size="sm">Sample Size</Heading>
              <FormControl>
                <FormLabel>Choose Sample Size</FormLabel>
                <Slider
                  aria-label="sample-size-slider"
                  min={10}
                  max={100}
                  value={sampleSize}
                  onChange={(value) => setSampleSize(value)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Text fontSize="xs">{sampleSize}</Text>
                  </SliderThumb>
                </Slider>
              </FormControl>
            </Stack>
          </Box>
          <Button
            px="4"
            colorScheme={'purple'}
            type="submit"
            onClick={startPreview}
            isLoading={isLoading}
            disabled={!selectedProject || isLoading}
          >
            Preview
          </Button>
        </Stack>
      </Stack>
      <Box>
        <Heading size="sm" mt={10} mb={4}>
          Preview Results for audience: {audience}
        </Heading>
        <Stack>
          <Text fontSize="sm" fontWeight="medium">
            {totalMatching} out of {totalAccounts} scored ({((totalMatching / totalAccounts) * 100).toFixed(2)}%)
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            {totalNotScored} out of {totalAccounts} not scored ({((totalNotScored / totalAccounts) * 100).toFixed(2)}
            %)
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            {totalDisqualified} out of {totalAccounts} disqualified (
            {((totalDisqualified / totalAccounts) * 100).toFixed(2)}%)
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            Total possible points: {totalPossiblePoints}
          </Text>
          <BucketSummary buckets={buckets} />
          <DistributionBar buckets={buckets} />
          <MiniScoreDistribution
            buckets={buckets}
            pointsHistogram={pointsHistogram}
            totalPossiblePoints={totalPossiblePoints}
          />
          <CollapsibleBucketTable buckets={buckets} disqualified={disqualified} />
          <DisqualifiedSummary
            totalAccounts={totalAccounts}
            totalMatching={totalMatching}
            disqualified={disqualified}
          />
        </Stack>
      </Box>
    </PageLayout>
  )
}
