import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { useSpaces } from '../../../data/use-spaces'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'
import SelectInput from '../../../ui/SelectInput'
import { accountViewPath } from '../lib/list-paths'

interface AccountViewModalProps extends UseDisclosureProps {
  view: AccountView
}

export function MoveToSpaceModal(props: AccountViewModalProps) {
  const disclosure = useDisclosure(props)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { data, isLoading } = useSpaces({ ownership: 'scoped' })
  const [spaceId, setSpaceId] = React.useState<AccountView['space_id']>(props.view?.space_id)

  useEffect(() => {
    setSpaceId(props.view?.space_id)
  }, [props.view.space_id])

  const onSubmit = React.useCallback(() => {
    setIsSubmitting(true)
  }, [])

  const spaces = useMemo(() => data?.spaces ?? [], [data?.spaces])

  const spaceOptions = useMemo(() => {
    const opts = spaces.map((space) => ({
      name: space.name + (space.id === props.view.space_id ? ' (current)' : ''),
      id: space.id
    }))

    if (props.view.space_id) {
      return [{ id: '', name: 'No space' }, ...opts]
    } else {
      return opts
    }
  }, [spaces, props.view.space_id])

  return (
    <Modal {...disclosure} size="md" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Move this list</ModalHeader>

        <ModalBody as="form" action={accountViewPath(props.view)} method="POST" id="edit-view-form" onSubmit={onSubmit}>
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PATCH" />

          {isLoading ? (
            <Flex height="32px" alignItems="center">
              <Spinner color="gray.400" size="sm" thickness="1.5px" />
            </Flex>
          ) : spaces.length === 0 ? (
            <Text fontSize="sm" color="gray.600">
              You don't have any Spaces to move this list to yet.{' '}
              <Link color="purple.500" href={projectPath('/spaces')} isExternal>
                Create a Space
              </Link>{' '}
              to get started.
            </Text>
          ) : (
            <FormControl size="sm" isRequired>
              <input type="hidden" name="account_view[ownership]" value={spaceId ? 'space' : 'shared'} />
              <input type="hidden" name="account_view[space_id]" value={spaceId || ''} />

              <FormLabel>Select a Space to move this list to:</FormLabel>
              <SelectInput
                size="md"
                variant="outline"
                items={spaceOptions}
                selectedItem={spaceOptions.find((space) => space.id === spaceId)}
                onSelectedItemChange={({ selectedItem }) => setSpaceId(selectedItem?.id || null)}
                itemToString={(item) => item?.name}
                placeholder="Select a space"
                popperOptions={{ matchWidth: true }}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            colorScheme="purple"
            form="edit-view-form"
            isLoading={isSubmitting}
            isDisabled={spaceId === props.view.space_id && !spaceId}
          >
            Move list
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
