import GmailEmailComposer from '@app/components/ui/gmailEmailComposer'
import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  Wrap
} from '@chakra-ui/react'
import {
  IconBolt,
  IconBoltFilled,
  IconBuilding,
  IconChartBubble,
  IconCheck,
  IconGolf,
  IconMailSpark,
  IconX
} from '@tabler/icons-react'
import capitalize from 'lodash/capitalize'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { CardHeading } from './CardHeading'
import CircleIcon from './CircleIcon'
import { AiSparklesIcon, LinkedinBoxIcon } from './icons'
import { InfoBox } from '@app/components/ui/InfoBox'
import { DetailsCard } from './Card'
import { Toggle } from '../pages/accounts/components/Toggle'
import { projectPath, useCurrentProject } from './ProjectsContext'
import { useQuery } from 'react-query'
import { subscribeToChannel, SubscriptionEmitter } from '../../channels/generic_channel'
import { concurrentCachedGET } from '../../lib/api'

export function useRecommendation(domain: string) {
  const project = useCurrentProject()
  const path = projectPath(`/inbox/${domain}/recommendation`)

  return useQuery(['ai-recommendation', { projectId: project?.id, domain }], () => concurrentCachedGET(path), {
    enabled: false
  })
}

export function useRecommendationStatus(job_id?: string, domain?: string) {
  const project = useCurrentProject()
  const subscription = React.useRef<SubscriptionEmitter | undefined>()
  const [response, setResponse] = React.useState<any>()

  React.useEffect(() => {
    if (subscription.current || !job_id || !domain) {
      return
    }
    // Reset the response when the job_id or domain changes
    setResponse(undefined)

    subscription.current = subscribeToChannel({
      channel: 'RecommendationChannel',
      project_slug: project?.slug,
      job_id,
      domain
    })

    const onData = (data) => {
      if (data.action === 'status') {
        setResponse(data.data)
      }
    }

    subscription.current.on('received', onData)

    return () => {
      subscription.current?.off('received', onData)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
  }, [project?.slug, job_id, domain])

  return response
}

function MarkdownText({ children }: { children: string }) {
  return (
    <Flex flexDirection="column" gap={4} fontSize="sm">
      <ReactMarkdown
        components={{
          h1: ({ node: _node, ...props }) => <Heading size="sm" {...props} />,
          h2: ({ node: _node, ...props }) => <Heading size="xs" {...props} />,
          h3: ({ node: _node, ...props }) => <Text fontSize="sm" fontWeight="medium" {...props} />,
          li: ({ node: _node, ...props }) => <ListItem pt="2" {...props} />,
          ul: ({ node: _node, ...props }) => <UnorderedList {...props} />,
          ol: ({ node: _node, ...props }) => <OrderedList {...props} />,
          a: ({ node: _node, href, ...props }) => (
            <HStack>
              <Link
                {...props}
                size="xs"
                colorScheme="linkedin"
                isExternal
                href={href?.startsWith('linkedin.com') ? `https://${href}` : href}
              />
            </HStack>
          )
        }}
      >
        {children}
      </ReactMarkdown>
    </Flex>
  )
}

interface AIRecommendationOutputProps {
  output: any
  hasGmail?: boolean
  targetEmail?: string
  linkedin_url?: string
  onEmailSent?: () => void
  children?: React.ReactNode
}

export default function AIRecommendationOutput(props: AIRecommendationOutputProps) {
  const { output, hasGmail, targetEmail, linkedin_url, children } = props
  if (!output) return <></>

  return (
    <Stack spacing={8}>
      <Stack>
        {output?.json_metadata?.strengths && (
          <Wrap>
            {output?.json_metadata?.strengths.map((label: string) => (
              <Badge
                key={label}
                variant="regular"
                colorScheme="green"
                border="1px solid"
                borderColor="green.200"
                rounded="full"
                px={2}
                py={0.5}
              >
                {capitalize(label.toLowerCase())}
              </Badge>
            ))}
          </Wrap>
        )}

        {output?.json_metadata?.weaknesses && (
          <Wrap>
            {output?.json_metadata?.weaknesses.map((label: string) => (
              <Badge
                key={label}
                variant="regular"
                colorScheme="red"
                border="1px solid"
                borderColor="red.200"
                rounded="full"
                px={2}
                py={0.5}
              >
                {capitalize(label.toLowerCase())}
              </Badge>
            ))}
          </Wrap>
        )}
      </Stack>

      {children}

      {output.json_metadata && (
        <Stack fontSize="sm" spacing="8">
          <Toggle defaultIsOpen title={<CardHeading icon={<AiSparklesIcon size={18} />}>AI Insights</CardHeading>}>
            <Stack as={DetailsCard} spacing="0">
              <Stack spacing={0}>
                <Heading size="xs">{output.json_metadata.recommendation.primary_tactic}</Heading>
                <Text color="gray.500">{output.json_metadata.recommendation.recommended_tactic}</Text>
              </Stack>

              {output.json_metadata.account_analysis.additional_notable_information && (
                <UnorderedList pl="4" spacing={1}>
                  {output.json_metadata.account_analysis.additional_notable_information.map((reason) => (
                    <ListItem key={reason}>{reason}</ListItem>
                  ))}
                </UnorderedList>
              )}

              <Toggle title={<Text color="gray.500">Reasoning</Text>} defaultIsOpen>
                <UnorderedList pl="4" spacing={1}>
                  {output.json_metadata.recommendation.reasoning.map((reason) => (
                    <ListItem key={reason}>{reason}</ListItem>
                  ))}
                </UnorderedList>
              </Toggle>
            </Stack>
          </Toggle>

          <Toggle
            title={
              <CardHeading icon={<Icon color="purple.500" as={IconBoltFilled} size="24px" />}>
                AI Signal analysis
              </CardHeading>
            }
          >
            <Stack as={DetailsCard} spacing="-1">
              {output.json_metadata.account_analysis.signal_analysis.map((point) => (
                <Stack key={JSON.stringify(point)} spacing={0}>
                  <HStack spacing={1}>
                    {point.present ? (
                      <CircleIcon variant="subtle" colorScheme="green" icon={IconCheck} size={4} />
                    ) : (
                      <CircleIcon variant="subtle" colorScheme="red" icon={IconX} size={4} />
                    )}
                    <Text fontSize="sm" fontWeight="medium">
                      {point.signal_name}
                    </Text>
                  </HStack>
                  <Text color="gray.500">{point.evidence}</Text>
                </Stack>
              ))}
            </Stack>
          </Toggle>
        </Stack>
      )}

      <Divider />

      {output.emails?.emails && (
        <Stack spacing={4}>
          <CardHeading icon={<IconMailSpark size={18} />}>Suggested messaging</CardHeading>
          {!targetEmail && linkedin_url && (
            <InfoBox colorScheme="orange">
              <Text>
                We couldn't enrich the email on this lead.{' '}
                <Tooltip label={`https://${linkedin_url.replace(/https?:\/\//, '')}`}>
                  <Text as="span">
                    But you can still reach out them on
                    <Link
                      ml="1"
                      display="inline"
                      color="linkedin.700"
                      isExternal
                      href={`https://${linkedin_url.replace(/https?:\/\//, '')}`}
                    >
                      Linkedin <LinkedinBoxIcon boxSize="18px" />
                    </Link>
                  </Text>
                </Tooltip>
              </Text>
            </InfoBox>
          )}
          {output.emails?.emails.map((email) => (
            <DetailsCard key={email.subject}>
              <Heading size="xs" fontWeight={'semibold'}>
                {email.subject}
              </Heading>
              <MarkdownText>{email.body}</MarkdownText>
              <Box ml="auto" display="inline-block">
                <GmailEmailComposer
                  connected={hasGmail!}
                  email={{ to: targetEmail, ...email }}
                  onEmailSent={props.onEmailSent}
                />
              </Box>
            </DetailsCard>
          ))}
        </Stack>
      )}

      {output && (
        <>
          <Divider />
          <Stack>
            <Heading size="sm" fontWeight={'semibold'} pb="2">
              Additional insights
            </Heading>

            {output?.intent_summary && (
              <Toggle title={<CardHeading icon={<IconBolt size={18} />}>Intent Report</CardHeading>}>
                <Box as={DetailsCard}>
                  <MarkdownText>{output?.intent_summary}</MarkdownText>
                </Box>
              </Toggle>
            )}

            {output?.analysis && (
              <Toggle title={<CardHeading icon={<IconBuilding size={18} />}>Business Profile Report</CardHeading>}>
                <Box as={DetailsCard}>
                  <MarkdownText>{output?.analysis}</MarkdownText>
                </Box>
              </Toggle>
            )}

            {output?.talking_points && (
              <Toggle title={<CardHeading icon={<IconChartBubble size={18} />}>Talking Points</CardHeading>}>
                <Box as={DetailsCard}>
                  <MarkdownText>{output?.talking_points}</MarkdownText>
                </Box>
              </Toggle>
            )}

            {output?.plays && (
              <Toggle title={<CardHeading icon={<IconGolf size={18} />}>Plays Report</CardHeading>}>
                <Box as={DetailsCard}>
                  <MarkdownText>{output?.plays}</MarkdownText>
                </Box>
              </Toggle>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}
