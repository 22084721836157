import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'
import React, { MouseEventHandler, useCallback, useRef, useState } from 'react'

interface Props {
  title?: string
  confirmLabel?: string
  isOpen: boolean
  isCentered?: boolean
  onConfirm?: MouseEventHandler<HTMLButtonElement>
  onClose: () => void
  variant?: 'callback' | 'submit'
  form?: React.RefObject<HTMLFormElement>
}

export function ConfirmDialog({
  children,
  variant = 'callback',
  title = 'Are you sure?',
  confirmLabel = 'Confirm',
  onConfirm,
  onClose,
  isOpen,
  isCentered,
  form
}: React.PropsWithChildren<Props>) {
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState(false)

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      setLoading(true)
      if (typeof onConfirm === 'function') {
        await onConfirm(event)
        onClose()
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onConfirm, onClose]
  )

  const onSubmitClick = useCallback(
    (event) => {
      if (form?.current) {
        form.current.requestSubmit()
        handleConfirm(event)
      }
    },
    [form, handleConfirm]
  )

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={isCentered}>
      <AlertDialogOverlay zIndex="popover">
        <AlertDialogContent>
          <AlertDialogHeader fontSize="md" paddingBottom={2}>
            {title}
          </AlertDialogHeader>
          <AlertDialogBody fontSize="sm">{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button size="sm" ref={cancelRef} mr={3} variant="outline" onClick={onClose}>
              Cancel
            </Button>

            <Button
              type={variant === 'submit' ? 'submit' : 'button'}
              size="sm"
              colorScheme="purple"
              isLoading={loading}
              onClick={variant === 'submit' ? onSubmitClick : handleConfirm}
              onSubmit={variant === 'submit' ? handleConfirm : undefined}
            >
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
