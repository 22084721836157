import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { FormEvent, useMemo } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { ProfileRecord } from '../../../../types/Profile'
import { useAppDep } from '../../../data/use-app-dep'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'

export type ApolloUser = { id: string; email: string; first_name: string; last_name: string }

interface TaskModalProps extends UseDisclosureProps {
  profile: ProfileRecord
}

export function ApolloTaskModal(props: TaskModalProps) {
  const disclosure = useDisclosure(props)
  const [saving, setSaving] = React.useState(false)
  const { data: userData } = useAppDep<'users', ApolloUser[]>('Apollo', 'users')
  const currentUser = useCurrentUser()
  const [dueDate, setDueDate] = React.useState(dayjs().add(1, 'hour').format('YYYY-MM-DDTHH:mm'))

  const onClose = disclosure.onClose
  const profileId = props.profile.id

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      postForm(projectPath(`/apollo/actions/tasks?profile_id=${profileId}`), data)
        .then(() => {
          toast.success('Task created!', {
            description: 'Your task has been created in Apollo.io.'
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error creating task', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [profileId, onClose]
  )

  return (
    <Modal {...disclosure} size="md" isCentered>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          <HStack>
            <CompanyAvatar src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/imnotvasciiltr2dikl8" />
            <Stack spacing="0">
              <Heading size="sm">New Apollo.io Task</Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                Contact: {props.profile.email}
              </Text>
            </Stack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pb="4" fontWeight={'normal'}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={'4'}>
              <HStack>
                <FormControl>
                  <FormLabel>Task Type</FormLabel>
                  <Select name="task[type]" size="sm" rounded="md" fontWeight="normal">
                    <option value="outreach_manual_email">Email</option>
                    <option value="action_item">Action Item</option>
                    <option value="linkedin_step_connect">Linked In: Send Connection Request</option>
                    <option value="linkedin_step_message">Linked In: Send Message</option>
                    <option value="call">Call</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Priority</FormLabel>
                  <Select name="task[priority]" size="sm" rounded="md" fontWeight={'normal'}>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </Select>
                </FormControl>
              </HStack>

              <HStack>
                <FormControl>
                  <FormLabel>Due Date</FormLabel>

                  <input
                    type="hidden"
                    name="task[due_date]"
                    value={dayjs(dueDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')}
                  />
                  <Input
                    type="datetime-local"
                    size="sm"
                    rounded="md"
                    variant={'outline'}
                    defaultValue={dueDate}
                    onSelect={(e) => {
                      const datetimeLocal = e.currentTarget.value
                      if (datetimeLocal !== '') {
                        setDueDate(new Date(datetimeLocal).toISOString())
                      }
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Assign To</FormLabel>
                  <Select
                    name="task[user_id]"
                    size="sm"
                    rounded="md"
                    fontWeight={'normal'}
                    defaultValue={userData?.data?.users?.find((u) => u.email === currentUser.email)?.id}
                  >
                    {userData?.data?.users?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>

              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea name="task[note]" size="sm" rounded="md" fontWeight={'normal'}></Textarea>
              </FormControl>

              <Button size="sm" colorScheme={'purple'} type="submit" isLoading={saving}>
                Create Task
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export type ApolloSequence = {
  id: string
  name: string
}

export type ApolloEmailAccount = {
  id: string
  email?: string
}

type ApolloLabel = {
  id: string
  name: string
}

interface AddToListModalProps extends UseDisclosureProps {
  profile: ProfileRecord
}

export function AddToListModal({ profile, ...props }: AddToListModalProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const [saving, setSaving] = React.useState(false)
  const [selectedLabel, setSelectedLabel] = React.useState<ApolloLabel | null>(null)

  const { data: listsData, isLoading: loadingLabels } = useAppDep<'labels', ApolloLabel[]>('Apollo', 'labels')
  const list = useMemo(() => selectedLabel ?? listsData?.data?.labels[0] ?? null, [selectedLabel, listsData])

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!list) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      postForm(projectPath(`/apollo/actions/lists?profile_id=${profile.id}`), data)
        .then(() => {
          toast.success('Prospect added to List!', {
            description: `${profile.email} has been added to ${list.name} in Apollo.io.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error adding prospect to list', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [profile, list, onClose]
  )

  return (
    <Modal {...disclosure} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <CompanyAvatar src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/imnotvasciiltr2dikl8" />
            <Stack spacing="0">
              <Heading size="sm">Add to Apollo.io List</Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                Contact: {profile.email}
              </Text>
            </Stack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pb="4" fontWeight={'normal'}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={'4'}>
              <FormControl>
                <FormLabel>Select a List</FormLabel>
                {loadingLabels && <Spinner size="sm" />}
                <ComboboxWithSearch
                  items={listsData?.data?.labels ?? []}
                  selectedItem={list}
                  onChange={(selectedItem) => {
                    setSelectedLabel(selectedItem)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                  itemToString={(item) => item?.name || ''}
                />
              </FormControl>

              <Flex w="100%" pt="4">
                <Button w="100%" size="sm" colorScheme={'purple'} type="submit" isLoading={saving}>
                  Add to List
                </Button>
              </Flex>
            </Stack>
            {list && <input type="hidden" name="list[label_id]" value={list.id} />}
            {list && <input type="hidden" name="list[label_name]" value={list.name} />}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
